.project-profitability-widget {
  white-space: normal;
  .profit-and-loss-detail {
    padding-top: 10px;
    &>.card {
      border: 1px solid $gray-300;
      margin: 10px;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
      table.profit-and-loss-detail-table {
        thead {
          tr {
            background-color: white;
          }
          th, td {
            color: $dark;
            border-left: none;
            border-right: none;
            background-color: white;
            background: none;
          }
        }
        tbody {
          tr {
            background-color: white;
            border-bottom-color: none;
            .section-title-line, .section-summary-line {
              td, th {
                cursor: pointer;
              }
            }
          }
          th,
          td {
            border-left: none;
            border-right: none;
            background-color: white;
            background: none;
            border-bottom: none;
            white-space: normal;
          }
          tr.section-title-line {
            cursor: pointer;

            &>.td,
            &>.th {
              cursor: pointer;
              white-space: normal;
            }
          }
          tr.summary-line {
            cursor: pointer;
            border-top-color: silver;
            border-top: 1px solid silver;
            border-bottom: 1px solid silver;
            border-bottom-color: silver;
            &>.td,
            &>.th {
              cursor: pointer;
              border-top: 1px solid silver;
              border-bottom: 1px solid silver;
              border-bottom-color: silver;
            }
          }
        }
      }
    }
  }
}
