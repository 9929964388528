.portal-checkbox {
  padding: calc(0.375rem + 3px) 0 0.375rem calc(0.75rem + 1px);
  display: flex;
  align-items: center;

  &.inline {
    padding: 0;
  }

  .field-container & {
    padding: 8px 8px 9px;
  }

  input[type="checkbox"] {
    width: 1.5em !important;
    height: 1.5em !important;
    // margin: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance:none;
    background-color: $white;
    border: 1px solid $checkbox-border-color;
    border-radius: $border-radius;
    box-shadow: none;
    position: relative;
    cursor: pointer;

    &:checked {
      border-color: $checkbox-checked-background;
      background-color: $checkbox-checked-background;
      color: $checkbox-checked-color;

      &:focus {
        border-color: $input-focus-border-color;
      }

      &:after {
        font-family: 'themify';
        content: '\e64c';
        line-height: 1.5em;
        width: calc(1.5em - 2px);
        height: calc(1.5em - 2px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:focus,
    &:active:not(:disabled) {
      border-color: $input-focus-border-color;
    }

    &+label {
      margin: 0 0 0 0.5em;
      user-select: none;
      cursor: pointer;

      &+.info-tooltip-wrapper {
        margin: 0 0 0 0.5em;
      }
    }
  }

  &.form-control-danger {
    input[type="checkbox"] {
      border-color: $danger;

      &+label {
        color: $danger;
      }
    }
  }

  &.disabled {
    opacity: 0.7;
    input[type="checkbox"] {
      cursor: default;
      &+label {
        cursor: default;
      }
    }
  }
}
