// Application Header Bar

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $top-bar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: $zIndex-topBar;
  box-shadow: 0px 1px 15px 1px rgba(73, 71, 78, 0.1);
  background: $themecolor;
  background: linear-gradient(to right, var(--topbar-start-color) 0%, var(--topbar-end-color) 100%);

  // Nav bar with nav links / nav control
  .nav-clickable,
  .UserMenu__toggle {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    line-height: 50px;
    background: transparent;
    border: medium none;
    color: rgba(255, 255, 255, 0.8);
    position: relative;

    &.btn-secondary:not(:disabled):not(.disabled):active,
    &.btn-secondary:not(:disabled):not(.disabled).active,
    &.btn-secondary:not(:disabled):not(.disabled):focus,
    &.show > .btn-secondary.dropdown-toggle {
      background: rgba(0, 0, 0, 0.05);
      box-shadow: unset;
      color: white;
    }
  }

  .toggle-visible {
    display: none;

    &.desktop {
      // iPad landscape and higher
      @media (min-width: $grid-lg-min) {
        display: block;
      }
    }

    &.mobile {
      // iPad portrait and lower
      @media (max-width: $grid-md-max) {
        display: block;
      }
    }
  }

  .UserMenu__toggle {
    opacity: 1;
    height: 100%;
  }

  .left-container {
    padding-left: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .center-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .api-debug-form {
    position: absolute;
    top: 65px;
    left: 50%;
    transform: translateX(-33vw);
    width: 66vw;

    // iPad portrait and lower
    @media (max-width: $grid-md-max) {
      width: 100%;
      left: 0;
      transform: none;
    }
  }

  .right-container {
    padding-left: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .version {
    font-size: 0.85rem;
    opacity: 0.25;
    line-height: 65px;
    color: white;
    float: left;
  }

  // Global Search
  .search-box {
    .app-search {
      position: absolute;
      margin: 0px;
      display: block;
      width: 100%;
      top: -1px;
      box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
      display: none;
      left: 0px;

      input {
        width: 100.5%;
        padding: 20px 40px 20px 20px;
        border-radius: 0px;
        font-size: 17px;
        transition: $sidebar-transition-speed ease-in;

        &:focus {
          border-color: $white;
        }
      }

      .srh-btn {
        background: $white;
        position: absolute;
        top: 23px;
        border: none;
        right: 20px;
        padding: 0 10px;
      }
    }
  }
}

// UserMenu dropdown menu
.UserMenu {
  &__toggle {
    right: 0;

    &::after {
      display: none;
    }

    .user-avatar-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .user-avatar-container {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      overflow: hidden;
      display: inline-block;

      .user-avatar {
        background: $gray-500;
        width: 40px;
        display: block;
      }
    }
  }

  &__menu {
    line-height: 1rem;
    box-shadow: $shadow;
    border: none;
    .item-wrapper{
      border: 1px solid $border-color;
      border-top: none;
      text-align: left;

      a.dropdown-item {
        padding: 0;

        i,
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
