.split-view {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;

  &.resizing {
    // Prevent the user from accidentally selecting the content while dragging the split handle
    user-select: none;
  }

  .split-view-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    &>* {
      flex-grow: 1;
    }

    &.left {
      border-right: 1px solid $gray-300;
      max-width: 50%; // This is overridden by the split handle
    }

    &.right {
      flex: 1;
      .cell-border-right {
        border-right: 1px solid $gray-300;
      }
    }
  }
}
