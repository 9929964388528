// Page Pre-loader
.preloader {
  width: 100%;
  height: calc(100 * var(--vh));
  left: 0;
  top: 0;
  position: fixed;
  z-index: 99999;
  background: #fff;

  .cssload-speeding-wheel {
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
  }
}

.loader,
.loader__figure {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  overflow: visible;
  padding-top: 2em;
  height: 0;
  width: 2em;
}

.loader__figure {
  height: 0;
  width: 0;
  box-sizing: border-box;
  border: 0 solid #1976d2;
  border-radius: 50%;
  animation: loaderFigure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.loader__label {
  float: left;
  margin-left: 50%;
  transform: translateX(-50%);
  margin: 0.5em 0 0 50%;
  font-size: 0.875em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  color: #1976d2;
  white-space: nowrap;
  animation: loaderLabel 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes loaderFigure {
  0% {
    height: 0;
    width: 0;
    background-color: #1976d2;
  }
  29% {
    background-color: #1976d2;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loaderLabel {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
