.mini-contract-summary {
  display: flex;
  flex-direction: row;

  .contract-number {
    display: flex;
    min-width: 5rem;
    color: $primary;
  }

  .client-name,
  .subtype-name {
    display: flex;
    white-space: nowrap;
  }
}
