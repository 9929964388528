.project-number {
  white-space: nowrap;
  min-width: 5rem;
  display: inline-block;

  .id-img {
    display: inline-block;
    margin-right: 6px;
    margin-top: -3px;
  } 
}