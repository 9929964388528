$treeLineThickness: 1pt;

.ptv-node {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3em;

  >i {
    margin-right: 0.75em;
  }

  span.ptv-l {
    cursor: pointer;
    max-height: 3em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:hover {
      text-decoration: underline;
    }
  }

  &.active {
    &> i {
      color: $primary;
      font-weight: 600;

      li.drag-source & {
        color: $gray-300;
      }
    }

    &> span.ptv-l {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;

      li.drag-source & {
        color: $gray-300;
      }
    }
  }

  .chevron {
    width: 1.5em;
    cursor: pointer;

    &::before {
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      position: relative;
      top: 0.5em;
      vertical-align: top;
      width: 0.45em;
      left: 0.75em;
      transform: rotate(45deg);
      transition: transform 0.25s linear, top 0.25s linear;
    }

    &.down {
      &:before {
        top: 0.4em;
        transform: rotate(135deg);
      }
    }
  }

  // Tree Lines
  .ptv-tl-wrapper {
    .ptv-tl {

      // This is the Container for the horizontal and vertical lines
      position: absolute;
      top: 0;
      width: 1.5rem;
      bottom: 0;
      left: calc((-1.5rem * var(--depth)) - 1.5rem - #{$treeLineThickness});

      // This is the horizontal component of the line
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0.5rem;
        right: 0.25rem;
        height: 0;
        border-top: $treeLineThickness solid $gray-600;
      }

      // This is the vertical component of the line
      &:after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0.5rem;
        border-left: $treeLineThickness solid $gray-600;
      }

      // End nodes have their vertical line snubbed
      &.end {
        &:after {
          bottom: 50%;
        }
      }

      &.ext {
        &::before {
          display: none;
        }
      }
    }
  }

  // Middle Sibling (ms)
  &.ms.show-connector,
  // Last Sibling (ls)
  &.ls.show-connector {
    .ptv-tl-wrapper {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0.5em;
        width: 1rem;
        bottom: 2.5em;
        border-left: $treeLineThickness solid $gray-600;
      }
    }
  }

  // First Sibling (fs)
  &.fs.show-connector,
  // Middle Sibling (ms)
  &.ms.show-connector,
  // Node with open children
  &.open {
    .ptv-tl-wrapper {
      &::after {
        content: "";
        position: absolute;
        top: 2.5em;
        left: 0.5em;
        width: 1rem;
        bottom: 0;
        border-left: $treeLineThickness solid $gray-600;
      }
    }
  }
}
