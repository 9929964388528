/**
 *  Potal Data Table / Table / Head
 **/

$blue-line: #37a7c8;
$grey-line: #cadadf;

.portal-data-table {
  .portal-datatable-head {
    & > th {
      position: relative;
      cursor: pointer;
      transition: opacity 0.15s;

      .column-header-inner {
        display: flex;
        flex-direction: column;
      }

      .title {
        flex-grow: 1;
      }

      .filter-type {
        font-style: italic;
        font-size: 13px;
        font-weight: 300;
        flex-grow: 1;
        padding-left: 0.1rem;
      }

      .column-sort-icon {
        margin-right: 0.5em;
      }

      .column-width {
        transition: opacity 0.25s;
        opacity: 0;
        cursor: col-resize;
        color: white;
        line-height: 0;
        position: absolute;
        transform: translate(20px, -17px);
        padding-left: 0;
        right: 0;
        top: 50%;

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.85;
      }
    }
  }

  // Originally from project.scss
  .datatable-header-filter {
    background: white !important;
  }

  .datatable-header-filter-col {
    vertical-align: bottom;
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: 0px !important;
    overflow: visible;

    .custom-select-container {
      position: relative;
      display: flex;

      .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 100%;

        .filter-input {
          margin: 0;
          border: none;
          background-color: transparent;
          width: 100%;
          min-width: 7em;
          height: 2.5em;
          min-height: 2.5em;

          padding: 6px 0.5em;
          line-height: 22px;

          &::placeholder {
            color: $input-placeholder-color;
          }

          &.text {
            min-width: 5em;

            &.date-picker {
              width: calc(50% - 40px);
            }
          }

        }

        .filter-range-arrow {
          color: $gray-400;
        }

        .filter-label {
          font-style: italic;
          width: 13%;
          text-align: right;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          margin: 0 0 0 1%;
          line-height: 13px;
          padding: 6px;
          background: #eee;
        }
      }

      .custom-filter-dropdown {
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        text-align: center;
        cursor: pointer;
        color: $gray-400;
        transition: color 0.25s ease;

        &:after {
          content: "";
          border-left: 1px solid $gray-400;
          top: 20%;
          bottom: 20%;
          left: 0;
          position: absolute;
        }

        &:hover {
          color: $gray-500;
        }

        .toggle {
          line-height: 34px;
        }
      }
    }

    .custom-select-container {
      border-bottom: 2px solid $grey-line;
    }

    .Select {
      border-bottom: 2px solid $grey-line;

      .Select__control {
        border-radius: 0;
        border: 0;
      }
    }

    &.has-filter {
      .custom-select-container {
        border-bottom: 2px solid $blue-line;
      }
    }

    .no-filter {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      background: #eee;
      border-bottom: 2px solid $blue-line;

      span {
        text-align: center;
        font-style: italic;
        opacity: 0.25;
        width: 100%;
      }
    }
  }

  .custom-select-container {
    & .custom-filter-dropdown+.input-wrapper {
      margin-right: 2rem;
      width: calc(100% - 2rem);
      max-width: calc(100% - 2rem);
    }
  }

  .table.table-sm .datatable-header-filter-col {
    .custom-select-container .input-wrapper .filter-input,
    .Select-placeholder {
      padding-left: 0.5em;
      padding-right: 0.5em;

    }
  }
}
