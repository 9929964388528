$chart-margin: 20px;
$half-chart-margin: calc($chart-margin / 2);

.exec-dashboard {
  .dashboard-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .chart-wrapper {
      background: $white;
      margin-bottom: $chart-margin;
      box-shadow: $shadow;

      flex-basis: 100%;
      max-width: 100%;

      .chart-title {
        padding: $chart-margin;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      &.aged-proposals {
        order: 1;

        @media (min-width: $grid-xl-min) {
          flex-basis: calc(50% - #{$half-chart-margin});
          max-width: calc(50% - #{$half-chart-margin});
        }
      }

      &.pipeline {
        order: 3;

        @media (min-width: $grid-xl-min) {
          order: 2;
          flex-basis: calc(50% - #{$half-chart-margin});
          max-width: calc(50% - #{$half-chart-margin});
        }
      }

      &.financial-year {
        flex-basis: 100%;
        max-width: 100%;

        &.current {
          order: 6;
        }
        &.last {
          order: 8;
        }
        &.last-2 {
          order: 10;
        }
      }
    }

    .drilldown-wrapper {
      flex-basis: 100%;
      max-width: 100%;

      &.aged-proposals {
        order: 2;

        @media (min-width: $grid-xl-min) {
          order: 3;
        }
      }

      &.pipeline {
        order: 4;
      }

      &.financial-year {
        &.current {
          order: 7;
        }
        &.last {
          order: 9;
        }
        &.last-2 {
          order: 11;
        }
      }
    }
  }
}
