.ptv-header {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  background: $white;
  box-shadow: $shadow;

  .ptv-header-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0.25em 0.5em 0.25em 0.75em;
    border-bottom: 1px solid $gray-300;
    min-height: 2.6rem;

    span.title {
      flex-grow: 1;
      margin: 0 0.5em;
    }

    .button-wrapper {
      // Space out adjacent buttons
      .btn+.btn {
        margin-left: 0.5em;
      }
    }

    &.search {
    }
  }

}
