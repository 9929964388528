.likelihood-viewer {
  line-height: 21px;
}

.likelihood-editor {
  line-height: 25px;
}

.likelihood-editor, .likelihood-viewer {
  padding: 0;
  width: 100%;
  min-width: 90px;
  max-width: 250px;
  position: relative;
  white-space: nowrap;

  .description {
    margin-left: 0.5em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .likelihood-square {
    display: inline-block;
    width: calc(10% - 1px);
    min-height: 10px;
    background-color: $gray-100;
    border-radius: 3px;
    transition: background-color 0.25s linear;
    margin-right: 1px;
    &:last-of-type {
      border-right: none;
    }
  }
  &.good .filled{
    background-color: rgb(73, 134, 95);
  }
  &.average .filled {
    background-color: rgb(152, 173, 57);
  }
  &.poor .filled {
    background-color: rgb(216, 173, 79);
  }
  &.bad .filled {
    background-color: rgb(216, 118, 79);
  }
}

.likelihood-editor {
  padding: calc(0.375rem + 1px) 0 0.375rem calc(0.75rem + 1px);

  &:hover {
    cursor: pointer;
    .likelihood-square {
      background-color: #EEE;
    }
    &.good .future{
      background-color: rgb(73, 134, 95);
    }
    &.average .future {
      background-color: rgb(152, 173, 57);
    }
    &.poor .future {
      background-color: rgb(216, 173, 79);
    }
    &.bad .future {
      background-color: rgb(216, 118, 79);
    }
  }

  .description {
    color: $text-muted;
  }
}
