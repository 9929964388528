.portal-modal.find-microsoft-ad-user-id-modal {
  .email-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    label {
      margin-bottom: 0;
    }

    .btn {
      white-space: nowrap;
    }

    >* {
      margin-right: 0.5em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .results-wrapper{
    .microsoft-account {
      display: flex;
      border-left: 0.5em solid $primary;
      align-items: center;

      &.disabled {
        border-left: 0.5em solid $text-muted;
      }

      &+.microsoft-account {
        margin-top: 1em;
      }

      .user-details {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0.5em;
      }
    }
  }
}
