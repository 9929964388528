// scaffolding

* {
  outline: none;
}

.currency {
  font-family: "Roboto";
}

body {
  margin: 0;
  overflow-x: hidden;
  color: $gray-600;
}

html {
  position: relative;
  min-height: 100%;
}

a {
  color: $themecolor;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: $body-color;
  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.box {
  border-radius: $border-radius;
  padding: 10px;
}

.no-wrap>table>thead>tr>td,
.no-wrap>table>thead>tr>th,
.no-wrap>table>tbody>tr>th,
.no-wrap>table>tbody>tr>td {
    white-space: nowrap;
}

// override required for above
.wrap-normal {
  td,
  th {
    white-space: normal;
  }
}

/*******************
Blockquote
*******************/

html body blockquote {
  border-left: 5px solid $primary;
  border: 1px solid $border-color;
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Padding margin property
*******************/

$vertical-property-map: (
  m-t-: margin-top,
  m-b-: margin-bottom,
  m-r-: margin-right,
  m-l-: margin-left,
  p-t-: padding-top,
  p-b-: padding-bottom,
  p-r-: padding-right,
  p-l-: padding-left,
  p-: padding,
  m-: margin,
);
$sizes-list: 5 10 15 20 25 30 40;
@each $size in $sizes-list {
  $val: $size + 0px;
  @each $keyword, $property in $vertical-property-map {
    html body .#{$keyword}#{$size} {
      #{$property}: $val;
    }
  }
}

/** * Zero */

$zero-property-map: (
  m-0: margin,
  m-t: margin-top,
  m-r: margin-right,
  m-b: margin-bottom,
  m-l: margin-left,
  p-0: padding,
  p-t: padding-top,
  p-r: padding-right,
  p-b: padding-bottom,
  p-l: padding-left,
);
@each $keyword, $property in $zero-property-map {
  html body .#{$keyword}-0 {
    #{$property}: 0;
  }
}

/*******************
Thumb size
*******************/

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}
.img-responsive {
  max-width: 100%;
  height: auto;
}
.radius {
  border-radius: $border-radius;
}

/*******************
Opacity
*******************/

.op-5 {
  opacity: 0.5;
}

.op-3 {
  opacity: 0.3;
}

/*******************
font weight
*******************/

html body {
  .font-bold {
    font-weight: $font-weight-bold;
  }
  .font-normal {
    font-weight: $font-weight-normal;
  }
  .font-light {
    font-weight: $font-weight-light;
  }
  .font-medium {
    font-weight: 500;
  }
  .font-16 {
    font-size: 16px;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-14 {
    font-size: 14px;
  }
  .font-10 {
    font-size: 10px;
  }
  .font-18 {
    font-size: 18px;
  }
  .font-20 {
    font-size: 20px;
  }
  .display-5 {
    font-size: 3rem;
  }
  .display-6 {
    font-size: 2.5rem;
  }
}

/*******************
Background colors
*******************/

html body {
  .bg-megna {
    background-color: $cyan;
  }
  .bg-theme {
    background-color: $themecolor;
  }
  .bg-inverse {
    background-color: $gray-900;
  }
  .bg-purple {
    background-color: $purple;
  }
  .bg-light {
    background-color: $gray-200;
  }
  .bg-white {
    background-color: $white;
  }
}

/*******************
Rounds
*******************/

.round {
  line-height: 48px;
  color: $white;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: $blue;
  line-height: 52px;
  img {
    border-radius: 100%;
  }
  &.round-info {
    background: $blue;
  }

  &.round-warning {
    background: $warning;
  }

  &.round-danger {
    background: $danger;
  }

  &.round-success {
    background: $success;
  }

  &.round-primary {
    background: $primary;
  }
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

/*******************
Labels
*******************/
.label-rounded {
  border-radius: 60px;
}

/*******************
 Badge
******************/

.badge {
  font-weight: 400;
  line-height: normal;
  padding: 0.25em 0.6em 0.25em 0.4em;
  &.badge-pill {
    padding: 0.2em 0.6em;
  }
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-dark {
  background-color: $gray-600;
}

/*******************
List-style-none
******************/

ul.list-style-none {
  margin: 0px;
  padding: 0px;
  li {
    list-style: none;
    a {
      color: $body-color;
      padding: 8px 0px;
      display: block;
      text-decoration: none;
      &:hover {
        color: $themecolor;
      }
    }
  }
}
/*******************
Card
******************/

.card {
  margin-bottom: 20px;
  .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: $text-muted;
  }
  .card-title {
    position: relative;
    font-weight: 500;
  }
  .card-actions {
    float: right;
    a {
      padding: 0 5px;
      cursor: pointer;
    }
  }
}
.card-alt {
  margin: 0 -20px;
  background: $card-alt;
}
.card-group {
  margin-bottom: 20px;
  .card {
    border-right: 1px solid $border-color;
  }
}
.card-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
}
.oh {
  overflow: hidden;
}
/*******************
Labels
*******************/

.label {
  padding: 3px 10px;
  line-height: 13px;
  color: $white;
  font-weight: 400;
  border-radius: $border-radius;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: $cyan;
}

.label-success {
  background-color: $success;
}

.label-info {
  background-color: $blue;
}

.label-warning {
  background-color: $warning;
}

.label-danger {
  background-color: $danger;
}

.label-megna {
  background-color: $cyan;
}

.label-primary {
  background-color: $primary;
}

.label-purple {
  background-color: $purple;
}

.label-red {
  background-color: $red;
}

.label-inverse {
  background-color: $gray-800;
}

.label-default {
  background-color: $gray-100;
}

/*******************
Display Classes
*******************/

@media (max-width: 575px) {
  html body .hidden-xs-down {
    display: none;
  }
}

@media (min-width: 769px) {
  html body .hidden-sm-up {
    display: none;
  }
}

@media (max-width: 767px) {
  html body .hidden-sm-down {
    display: none;
  }
}

@media (min-width: 768px) {
  html body .hidden-md-up {
    display: none;
  }
}

@media (max-width: 991px) {
  html body .hidden-md-down {
    display: none;
  }
}

@media (min-width: 992px) {
  html body .hidden-lg-up {
    display: none;
  }
}

@media (max-width: 1199px) {
  html body .hidden-lg-down {
    display: none;
  }
}

@media (min-width: 1200px) {
  html body .hidden-xl-up {
    display: none;
  }
}

// Useful Formats
.mono-text {
  font-family: "Roboto";
}

