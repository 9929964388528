// App

/*==============================================================
  For all pages
 ============================================================== */

#main-wrapper {
  min-width: 320px;
  min-height: calc((var(--vh) * 100) - 1px);
  width: 100%;
  overflow-y: auto;
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: max-width 0.5s ease, width -0.5s ease;
  padding-top: $top-bar-height;
  min-height: calc((var(--vh) * 100) - #{$top-bar-height});

  .animate-sidebar & {
    transition: margin-left 0.5s ease, max-width 0.5s ease, width 0.5s ease;
  }

  >.container-fluid {
    margin-left: 0;
    min-height: calc((var(--vh) * 100) - 1rem - #{$top-bar-height});
  }

  .fullscreen-enabled >.container-fluid {
    min-height: calc((var(--vh) * 100));
  }
}

body {
  --max-page-width: 100%;
}

.container-fluid {
  padding: 0px 20px 20px 20px;
}

.shadow {
  box-shadow: $shadow !important;
}

a {
  span + i,
  i + span {
    margin-left: 0.25em;
  }
}

/*============================================================*\
* Breadcrumb and page title
\*============================================================*/

.page-titles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  width: 100%;

  // Accommodate the removal of the gutter in mobile viewports
  @media (max-width: $grid-sm-max) {
    width: calc(100% - 30px);
    margin: 15px;
  }

  // Allow the action buttons to wrap in xs viewport
  @media (max-width: $grid-xs-max) {
    flex-wrap: wrap;
  }

  .title-and-breadcrumbs {
    flex: 1;
  }

  .title-wrapper {
    h4 {
      margin: 0;
    }

    .form-inline {
      margin-bottom: 0.5em;
    }
  }

  .breadcrumbs-wrapper {
    .breadcrumb {
      padding: 0;
      margin: 0;
      background: transparent;
      font-size: 0.75em;

      li {
        a {
          color: $body-color;

          &:active, &:hover, &:active:hover {
            color: $themecolor;
          }
        }
      }

      .breadcrumb-item + .breadcrumb-item::before {
        content: '\e649';
        font-family: themify;
        color: $gray-600;
        font-size: 0.5em;
        margin-top: 0.85em;
      }

      .breadcrumb-item.active {
        color: $themecolor;
        font-weight: 500;
      }
    }
  }

  &.project {
    h4 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .badge {
      margin-left: 0.5em;
    }
  }

  .loading-caption {
    margin-left: 0.5em;
  }
}

.dropdown-menu {
  padding: 0px;
  min-width: 5rem;
  border: $dropdown-border-color 1px solid;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  z-index: +1;

  .with-arrow {
    position: absolute;
    height: 10px;
    overflow: hidden;
    width: 40px;
    top: -10px;

    > span {
      transform: rotate(45deg);
      background-color: $white;
      width: 15px;
      height: 15px;
      top: 3px;
      left: 15px;
      position: absolute;
      content: '';
      -moz-border-radius: 6px 0 0 0;
      border-radius: 6px 0 0 0;
      /*box-shadow:-3px -3px 8px rgba(0, 0, 0, 0.1);    */
    }
  }

  .dropdown-item {
    @each $color, $value in $theme-colors {
      &.#{"" + $color} {
        background-color: $value;
      }
    }
    @each $color, $value in $theme-colors-foreground {
      &.#{"" + $color} {
        color: $value;
      }
    }

    .badge-count-wrapper {
      display: inline-block;
      font-size: 0.65em;

      .badge {
        margin-left: 0.5em;
        vertical-align: super;
      }
    }
  }

  .dropdown-divider {
    margin: 0;
  }

  &.dropdown-menu-right {
    .with-arrow {
      right: 0px;
      > span {
        right: 20px;
        left: auto;
      }
    }
  }
}

/*==============================================================
 Footer
 ============================================================== */

.footer {
  bottom: 0;
  color: $body-color;
  left: 0px;
  padding: 17px 15px;

  right: 0;
  border-top: 1px solid $border-color;
  background: $white;
}

.btn {
  >i+span{
    margin-left: 0.5em;
  }
}

div.split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
