/**
 *  Portal Table / Table / Cell
 **/
.portal-data-table {
  .portal-data-field {
    cursor: pointer;
    .mono-font {
      font-family: "Roboto";
    }

    &.not-editable {
      cursor: unset;
    }
  }

  .totals-row, .header-row {
    .portal-data-field {
      cursor: unset;
    }
  }
}
