$grid-border-color: $gray-300;
$animation-speed: 300ms;

.sales-forecast-dashboard {
  &.container-fluid {
    display: flex;
    flex-direction: column;
    min-height: calc((var(--vh) * 100) - #{$top-bar-height});

    .fullscreen-enabled & {
      background-color: $body-bg;
    }
  }

  // Settings Modal
  &.portal-modal.settings {
    .form-check {
      margin-top: 0.5em;

      .form-check-label {
        span {
          margin-left: 0.5em;
        }
      }
    }

    div+.separator {
      margin-top: 2em;
    }

    .fields,
    .options {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      > * {
        flex-basis: 40%;
        padding: 0.5em;
        margin-bottom: 1em;
      }
    }
  }

  .page-titles {
    .actions-wrapper {
      .settings {
        display: flex;
        flex-wrap: nowrap;

        &> button {
          margin-left: 0.25em;
        }

        &> .year-month-picker{
          margin-left: 1em;
        }
      }
    }
  }

  .card.sales-forecast{
    flex-grow: 1;
    margin-bottom: 0;

    .sales-forecast-table.show-project-names .scroll-container table tr.project td .cell-wrapper {
      height: 4em;
    }

    .sales-forecast-table {
      position: absolute;
      display: flex;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      overflow: hidden;

      &.resizing {
        // Prevent the user from accidentally selecting the content while dragging the split handle
        user-select: none;
      }

      img + span,
      i + span {
        margin-left: 0.5em;
      }

      .scroll-container {
        position: relative;
        height: 100%;
        max-height: 100%;
        overflow: auto;
        padding-bottom: 2em;

        &.left {
          border-right: 1px solid $grid-border-color;
          max-width: 50%; // This is overridden by the split handle
        }

        &.right {
          flex: 1;
          .cell-border-right {
            border-right: 1px solid $grid-border-color;
          }
        }

        .fixed-buttons {
          position: sticky;
          top: 0;
          left: 0;
          height: 0;
          width: 100%;
          overflow: visible;
          z-index: 2;

          .fixed-buttons-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 1em;
            height: 4em;

            >button {
              margin-left: 0.5em;
            }
          }
        }

        table {
          width: fit-content;
          height: fit-content;
          border-spacing: 0;
          border-collapse: separate;

          // Make the headers sticky
          thead {
            tr {
              th {
                position: sticky;
                top: 0;
                z-index: +1; // Not to sure about this.
              }

              &:first-of-type{
                th {
                  .cell-wrapper {
                    height: 4em;
                  }
                }
              }

              &:nth-of-type(2){
                th {
                  top: 4em;
                }
              }
            }
          }

          tr {
            td,
            th {
              padding: 0;

              &.currency {
                font-family: "Roboto";
              }

              .cell-wrapper {
                position: relative;
                height: 3em;
                line-height: 1em;
                padding: 0 1em;
                white-space: normal;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }

              &.text-right {
                .cell-wrapper {
                  justify-content: flex-end;
                }
              }
              &.text-center {
                .cell-wrapper {
                  justify-content: center;
                }
              }
            }

            th {
              height: 3em;
              background-color: $white;
              font-weight: 500;

              &.month {
                .cell-wrapper {
                  .month-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .label-wrapper {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }

              .cell-wrapper {
                border-bottom: 1px solid $grid-border-color;
              }
            }

            td {
              height: 2.5em;
              background-color: $white;
              border-bottom: 1px solid $white;
              transition: opacity $animation-speed ease-in-out;

              .cell-wrapper {
                white-space: nowrap;
                height: 2.5em;
              }
            }

            &.error {
              td {
                .cell-wrapper {
                  color: $danger;
                }
              }
            }

            &.total {
              td {
                background-color: $gray-100;
                .cell-wrapper {
                  font-weight: 500;
                  border-top: 2px solid $grid-border-color;
                }
              }
            }

            &.loading {
              &:not(.no-data){
                td {
                  opacity: 0.5;
                }
              }
            }

            &.geographical-state {
              &.data:not(.no-data) {
                td {
                  color: $primary;
                }
              }

              &.expanded {
                td {
                  background-color: lighten($primary, 65);
                  font-weight: 500;
                  border-top: 1px solid $grid-border-color;
                }
              }
            }

            &.project-manager {
              &.summary {
                td {
                  &:first-of-type {
                    padding-left: 1em;
                  }
                }
              }

              &.expanded {
                td {
                  background-color: lighten($green, 67);
                  font-weight: 500;
                  border-top: 1px solid $grid-border-color;
                  color: $green;
                }
              }

              &+.geographical-state {
                td {
                  border-top: 1px solid $grid-border-color;
                }
              }
            }

            &.project {
              &.summary {
                td {
                  &:first-of-type {
                    padding-left: 2em;
                    .cell-wrapper {
                      display: flex;
                      flex-direction: column;
                      line-height: 1.5em;
                      align-items: flex-start;
                      justify-content: center;

                      .project-no-and-status{
                        display: flex;
                        align-items: left;
                        // justify-content: space-between;
                        width: 100%;
                      }

                    }
                  }
                }
              }

              &.loading {
                td {
                  &:first-of-type {
                    .cell-wrapper {
                      height: 1.5em;
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: flex-start;
                    }
                  }
                }
              }

              &+.project-manager {
                td {
                  border-top: 1px solid $grid-border-color;
                }
              }
            }

            &.no-data {
              td {
                color: $text-muted;
              }
            }

            &.expandable {
              cursor: pointer;
            }

            &.geographical-state.hover,
            &.project-manager.hover,
            &.project.hover,
            &.hover {
              td {
                background: lighten($primary, 60%);
                border-bottom: 1px solid $primary;
              }
            }
          }
        }
      }
    }
  }
}

