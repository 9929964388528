.pdf-viewer {
  display: none;
  width: 100vw;
  height: calc(var(--vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;

  &.closing {
    display: block;
    animation: dialogClose 1s ease 1 normal both;
  }

  &.open {
    display: block;
    animation: dialogOpen 1s ease 1 normal both;
  }

  .viewer-overlay {
    width: 100vw;
    height: calc(var(--vh) * 100);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.33);
  }

  .draggable-container,
  .scale-container {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transition: 0.2s transform;
    transform-origin: center center;
    transform-style: flat;
    width: 66%;
    margin: 60px auto;

    canvas {
      width: 100%;
      cursor: grab;
    }
  }

  .reset-zoom {
    width: 130px;
  }

  .toolbar {
    position: fixed;
    background: white;
    width: 100vw;
    padding: 0.75rem;
    top: 0;
    left: 0;
    margin: 0;

    .controls-and-title {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;

      .navbar-brand {
        flex: 1;
        margin-right: 0.75rem
      }

      .controls {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        >* {
          margin-right: 0.75rem
        }

        .btn-group {
          white-space: nowrap;
        }
      }
    }
  }
}
