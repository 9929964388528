.ptv {
  position: relative;
  background-color: $white;
  min-height: 5rem;
  display: flex;
  flex-direction: column;

  .ptv-tree-wrapper {
    padding: 0;
    overflow: auto;
    max-height: 100%;
    max-width: 100%;
    flex-grow: 1;
    position: relative;

    div.no-items, div.loading {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.no-items {
      color: $text-muted;
    }

    div.loading {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }

    ul.ptv-tree {
      // Don't indent the first level
      list-style: none;
      padding-inline-start: 0;
      margin-inline-start: 0;
      margin: 0;
      min-height: 100%;
      height: 100%;
      position: relative;

      &.is-locked {
        color: $text-muted;
      }

      // First list item inside the root node which contains the main body of the tree
      &>li {
        display: block;
        min-height: 100%;
        padding: 0.75rem 1.5rem;
      }

      ul {
        position: relative;
        list-style: none;
        padding-inline-start: 0;
        margin-inline-start: 1.5rem;
      }

      li {
        position: relative;

        &.drop-target {
          &::before{
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: -0.5rem;
            background-color: #e6f4fb;
          }
        }

        &.drag-source {
          color: $gray-300;
        }
      }

      &.hide-root-node {
        &>li {
          &>ul {
            padding-inline-start: 0;
            margin-inline-start: 0;
          }
        }
      }

      .hidden-root-node {
        display: none;
      }
    }
  }

  .alert {
    margin-bottom: 0;
    white-space: normal;
  }
}

// TODO: These styles are specifically for the context menu and need to be moved once the context menu is decoupled from the treeview
.ptv-context-menu {
  i+span, span+i{
    margin-left: 0.75em;
  }

  // Hide separator lines that end up at the top and button of the menu due to dynamically hidden items
  .react-contexify__separator:first-child,
  .react-contexify__separator:last-child,
  // As do separator lines that are next to each other
  .react-contexify__separator+.react-contexify__separator {
      display: none;
  }
}
