.widget.collection-widget.files-widget {
  input[type="file"] {
    display: none;
  }

  .collection-widget-items-container {
    overflow: hidden;

    .collection-widget-items-inner {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 1rem 1rem 0;
    }
  }

  .file-drop-target {
    top: 3em;
  }
}
