.room-designer-page {
  width: 100%;
  height: calc((var(--vh) * 100) - #{$top-bar-height});
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
