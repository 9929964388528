// Custom Variables

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700');

// Theme Colors
$themecolor: $ci-blue;
$themecolor-dark: scale-color($ci-blue, $lightness: -25%);
$themecolor-alt: scale-color($ci-blue, $lightness: 25%);
$sidebar: #fff;
$sidebar-alt: #e8eff0;
$sidebar-text: #54667a;
$sidebar-icons: #a6b7bf;
$card-alt: #e4e9ef;

// Shadow
$shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

// Bootstrap overrides

//
// Color system
//

$white: #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #4f5467 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000000 !default;

$blue: #0091e6 !default;
$indigo: #8b00ad !default;
$purple: #7460ee !default;
$pink: #ad0078 !default;
$red: #c62f3e !default;
$orange: #ad3500 !default;
$yellow: #f6c83e !default;
$green: #00ad35 !default; // Updated to a green that is actually green!
$mediumAqua: #36bea6 !default;
$teal: #00ad8b !default;
$cyan: #01c0c8 !default;
$primary: $ci-blue; // changed by us
$text-muted: $gray-500 !default;
$text-info: $blue !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  mediumAqua: $mediumAqua,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
);

$theme-colors: (
  primary: $primary,
  secondary: $gray-100,
  success: $green,
  info: $blue,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  cyan: $cyan,
  dark: $gray-800,
  purple: $purple,
  green: $green,
  mediumAqua: $mediumAqua,
  blue: $blue,
  gray: $gray-400,
);

$theme-colors-foreground: (
  primary: $white,
  secondary: $gray-800,
  success: $white,
  info: $gray-800,
  warning: $gray-800,
  danger: $white,
  light: $gray-800,
  cyan: $gray-800,
  dark: $white,
  purple: $white,
  green: $white,
  mediumAqua: $white,
  blue: $white,
  gray: $white,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: #edf1f5;
$body-color: $gray-900 !default;
$grid-gutter-width: 20px !default;

// Notifications
$notification-bg: $body-bg;
$notification-color: $body-color;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;
$font-size-sm: ($font-size-base * 0.874) !default;
$font-family-sans-serif: 'Rubik', sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$h1-font-size: 36px !default;
$h2-font-size: 30px !default;
$h3-font-size: 24px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;

$headings-margin-bottom: calc(1rem / 2) !default;
$headings-font-weight: 400 !default;
$headings-color: inherit !default;

// Cards
$card-border-width: 0px !default;
$card-border-color: transparent !default;

$card-border-radius: 0px !default;

// Dropdowns

$dropdown-item-padding-y: 0.65rem !default;
$dropdown-border-color: $gray-400;
$dropdown-divider-bg: $gray-400;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $gray-100 !default;
$table-cell-padding: 1rem !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;

// Components

$component-active-color: $white !default;
$component-active-bg: $themecolor !default;
$badge-pill-padding-x: 0.2em !default;
$badge-pill-padding-y: 1em !default;
$busy-overlay-background: rgba(0, 0, 0, 0.25);

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-300;
$input-group-addon-border-color: $gray-300;
$input-btn-focus-color: rgba(0, 0, 0, 0.25) !default;
$input-focus-border-color: $themecolor-alt !default;
$custom-control-indicator-active-bg: rgba(0, 0, 0, 35%) !default;
$input-btn-focus-box-shadow: transparent !default;
$input-placeholder-color: rgba($body-color, 0.3) !default;
$checkbox-border-color: $gray-500;
$checkbox-checked-background: $themecolor;
$checkbox-checked-color: $white;

// Top Bar Height
$top-bar-height: 4rem;

//
// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-color: $gray-200 !default;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

// Progress bars
$progress-height: auto !default;

// Tabs
$nav-tabs-link-active-bg: $white !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-xs-max: 575px;
$grid-sm-min: 576px;
$grid-sm-max: 767px;
$grid-md-min: 768px;
$grid-md-max: 991px;
$grid-lg-min: 992px;
$grid-lg-max: 1199px;
$grid-xl-min: 1200px;

$sidebar-transition-speed: 0.4s;
$sidebar-width: 260px;
$sidebar-width-collapsed: 70px;
