.portal-login-form-container.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh) * 100);
  max-width: unset;
  display: flex;
  justify-content: center;
  align-items: center;

  .portal-login-form-card {
    display: block;
    background: url('../../../images/ci-logo-large.png') no-repeat $white;
    background-position: -250px -100px;
    background-size: 75%;
    box-shadow: 0 0 40px rgba(208, 208, 208, 0.5);
    width: 750px;
    color: $gray-600;

    @include media-breakpoint-down(sm){
      background: $white;
    }

    .portal-login-form {
      margin-top: 50px;
      padding: 0 50px;

      @include media-breakpoint-down(sm){
        margin-top: 1rem;
        padding: 0 5%;
      }

      .portal-login-head-wrapper {
        display: block;

        @include media-breakpoint-down(sm){
          display: flex;
          align-items: center;
        }

        .portal-login-logo {
          display: none;
          width: 20%;
          margin: 0 1rem;

          @include media-breakpoint-down(sm){
            display: block;
          }

          img {
            max-width: 100%;
          }
        }

        .portal-login-title-wrapper {
          .portal-login-head {
            text-transform: uppercase;
            text-align: center;
            font-size: 16px;
            letter-spacing: 0.2px;

            @include media-breakpoint-down(sm){
              text-align: left;
            }
          }

          .portal-login-head-sub {
            display: block;
            font-size: 10px;
            margin: 10px 0;
            text-align: center;
            text-transform: uppercase;

            @include media-breakpoint-down(sm){
              margin-bottom: 0;
              text-align: left;
            }
          }
        }
      }

      .form-control {
        border-color: $gray-500;
      }
    }

    .form-group {
      margin-bottom: 15px;
    }
  }
}
