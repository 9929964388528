.widget.project-admin-widget {
  background: $white;
  padding: 1em;
  white-space: normal;

  .function-section {
    +.function-section {
      margin-top: 1em;
      padding-top: 1em;
      border-top: 1px solid $text-muted;
    }
  }
}
