.widget.collection-widget.event-tracking-widget {
  .collection-widget-items-container {
    max-height: 500px;
    overflow-x: auto;

    .tracking-event {
      padding: 8px 5px;
      @include striped-children;
      display: flex;

      .user-avatar-container {
        @include user-avatar-container;
      }

      .event-info {
        flex: 1;

        .status-progression {
          margin: 0.25em 0;
        }

        .user-name {
          margin-right: 0.5em;
        }
        .time-to-event {
          font-style: italic;
          color: $gray-500;
        }
      }
    }
  }
}
