.widget.collection-widget.user-notifications-widget {
  .collection-widget-items-container {
    max-height: 500px;
    .collection-widget-items-inner {
      flex-direction: column;

      .user-notification {
        padding: .4rem;
        @include striped-children;
        .notification-shortmessage {
          padding: .4rem;
        }
        .notification-link {
          padding: .4rem;
        }
        .notification-time {
          padding: .4rem;
          font-style: italic;
          color: $gray-500;
        }
      }
    }
  }
}
