.ms-teams-detector {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  min-height: calc(var(--vh) * 100);
  display: block;
  overflow: auto;

  @media (min-width: $grid-sm-min) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .content {
    position: relative;
    background: $white;
    padding: 1em;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: $grid-md-min) {
      box-shadow: $shadow;
      border: 1px solid $gray-200;
      max-width: 30rem;
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em 0 2em 0;
      width: 100%;

      >* {
        margin: 0 0.5em;
      }

      img {
        width: 3em;
      }

      i {
        font-size: 3em;
      }
    }

    .request-wrapper {
      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        >* {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @media (min-width: $grid-sm-min) {
          flex-direction: row;

          >* {
            margin-bottom: 0;
            margin-right: 1em;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
