.news-creation-error {
  height: calc(50 * var(--vh));
  // height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-news-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50rem;

  input.file-upload {
    display: none;
  }

  // Minor change to the page titles to support the status inline with the title
  .page-titles .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h4 {
      margin: 0 0.5em 0 0;
    }
    margin-bottom: 0.5rem;
  }

  .form-control:focus {
    color: $body-color;
  }

  .edit-news-article {
    .article-metadata {
      display: flex;
      flex-wrap: wrap;
      padding: 1em;

      .title-wrapper,
      .author-wrapper,
      .teaser-wrapper,
      .image-wrapper {
        flex-basis: 100%;
        max-width: 100%;
      }

      // Share the top row with the title and author in desktop mode
      @media (min-width: $grid-md-min) {
        justify-content: space-between;

        // 50% Width with 3em margins
        .title-wrapper,
        .author-wrapper {
          flex-basis: calc((100% - 1em) * 0.5);
          max-width: calc((100% - 1em) * 0.5);
        }
      }

      textarea.article-title {
        font-size: 1.5em;
        font-weight: 500;
      }

      .author-wrapper {
        display: flex;
        align-items: flex-start;

        .author-details {
          flex: 1;
          display: flex;
          width: 100%;
          align-items: flex-start;
          flex-direction: column;

          .field-value {
            @media (min-width: $grid-md-min) {
              height: 56px; // Just to make it look nice against the article title when side by side
            }
          }

          .avatar-wrapper {
            border-radius: 100%;
            width: 3em;
            height: 3em;
            overflow: hidden;
            display: inline-flex;
            justify-content: space-around;
            align-items: flex-start;
            margin-right: 0.5em;

            .user-avatar {
              background: $white;
              object-fit: cover;
              width: 100%;
            }
          }

          .author {
            min-width: 50%;
          }
          .article-status {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            span.badge {
              font-size: 1.5em;
              line-height: 1em;
            }
          }
          .original-creator {
            margin: 0 0 1em;
          }
        }
      }

      .category-wrapper {
        width: 100%;
      }

      .image-container {
        position: relative;
        flex: 1;
        overflow: hidden;
        background-color: $gray-200;
        width: 100%;
        padding-top: 50%;

        .image {
          position: absolute;
          background-repeat: no-repeat;
          background-position: center top;
          background-size: cover;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        .image-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .upload-progress {
            background-color: rgba(0, 0, 0, 0.75);
            padding: 1em;
            width: 50%;
            position: relative;
            text-align: center;

            .progress-description {
              color: $white;
            }

            .progress {
              width: 100%;
              margin: 2em 0;
            }
          }

          .select-image-button {
            color: rgba(255, 255, 255, 0.75);
            background: rgba(0, 0, 0, 0.25);
            padding: 1em;
            border-radius: 1em;

            i {
              font-size: 5rem;
            }

            &:hover {
              color: $white;
              background: $primary;
              cursor: pointer;
            }
          }
        }
      }
    }

    .body-wrapper {
      flex-basis: 100%;
      padding: 0 1em 1em;

      img {
        width: 100%;
      }

      .loading-editor-placeholder {
        display: flex;
        align-items: center;
        margin: 1em 1em 1em 0;
        img {
          margin-right: 0.5em;
          width: 1em;
        }
      }

      .mce-content-body {
        padding: 1em;
        border: 1px solid $input-border-color;
        min-height: 10em;

        &::before{
          padding-left: 1em;
        }

        &[contenteditable="false"] {
          opacity: 0.7;
        }

        .news-article-column-wrapper {
          display: flex;
          margin-bottom: 1rem;

          .news-article-column {
            flex: 1;
            padding: 1em;

            border: 1px dashed $gray-500;
            border-right: none;
            &:last-of-type {
              border-right: 1px dashed $gray-500;
            }
          }
        }
      }
      .mce-edit-focus {
        border: 1px solid $input-focus-border-color;
      }
      p {
        text-align: left;
        margin-bottom: 2em;

        &.right {
          text-align: right;
        }
        &.center {
          text-align: center;
        }
        &.justify {
          text-align: justify;
        }
      }
    }

    .field-title {
      text-transform: uppercase;
      color: $primary;
      font-size: 1rem;
      margin: 1em 0 0.5em;

      .info-tooltip {
        margin-left: 0.5em;
      }
    }

    .field-value {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .action-button-wrapper {
      flex-basis: 100%;
      padding: 0 1em 1em;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (min-width: $grid-md-min) {
        padding: 0 1em 1em;
      }

      button {
        margin-right: 1em;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .error-wrapper {
      margin: 0 1em 1em;
    }
  }
}
