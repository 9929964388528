.view-select  {
  .view-select-dropdown-toggle {
    max-width: 80vw;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    padding-left: 0;
    overflow: hidden;
    max-width: 100%;

    .view-title {
      margin: 0 0.5em 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $gray-600;
    } 
  }
}
