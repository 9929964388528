// Card View for projects and tasks
.card-view {
  @import './project-card';
  @import './card-swimlanes';

  .header-body {
    display: flex;
    justify-content: space-between;

    .Select.const-select {
      width: 100%;
    }
  }
}
