.record-action-buttons {

  background: $white;

  .btn {
    margin-right: 0.5em;
  }

  &.in-footer {
    padding: 1rem 0 0 0.5em;
    border-top: $dropdown-border-color 1px solid;
    min-height: calc(3rem + 2px); // Basically the height of buttons (2px = button border top + bottom)
    position: relative;

    .btn {
      margin-bottom: 1rem;
    }
  }

  .loading,
  .no-actions {
    display: flex;
    height: calc(100% - 0.5em);
    align-items: center;
    justify-content: flex-start;
  }

  .no-actions {
    color: $text-muted;
  }

  .alert {
    margin-right: 0.5em;
    white-space: normal;
  }
}
