.record-detail-view {
  padding: 0 !important;
  position: relative;
  background: $gray-100;
  min-height: calc(15 * var(--vh));

  opacity: 1;
  transition: opacity 0.125s ease-in;
  pointer-events: auto;
  white-space: normal;

  &.busy {
    opacity: 0.7;
    pointer-events: none;
  }

  .project-heading {
    background: #d8e0e2;
    padding: 10px;
    color: black;
    font-weight: bold;
    .project-name {
      color: #5f5f5d;
    }
  }

  .tab-content-wrapper {
    border: $dropdown-border-color 1px solid;
    border-top: 0;
    background-color: $white;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  }

  .tab-pane {
    position: relative;
    height: 100%;
  }

  .record-tab-container {
    margin: 0 0.5em 0.5em;

    .tab-content{
      min-height: calc(15 * var(--vh));
      position: relative;
    }
  }

}
