// This is the placeholder text for the TinyMCE editor
.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
  padding-left: 4px;
  color: $text-muted;
  cursor: text;
}

.tox.tox-tinymce, .mce-content-body {
  border-radius: 5px;
  border-color: #dee2e6;
}
