.news-article {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50rem;
  padding: 0;

  @media (min-width: $grid-md-min) {
    padding: 1em;
  }

  .article-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;

    .header-wrapper {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .action-buttons-wrapper{
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .action {
          margin-right: 0.5em;
        }
      }
    }

    .article-title {
      margin-bottom: 1rem;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      h2 {
        margin: 0;
      }
    }

    .author-details {
      display: flex;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;

      @media (min-width: $grid-sm-min) {
        flex-wrap: nowrap;
      }

      .avatar-wrapper {
        margin-bottom: 1rem;
        border-radius: 100%;
        width: 3em;
        height: 3em;
        overflow: hidden;
        display: inline-flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-right: 0.5em;

        .user-avatar {
          background: $white;
          object-fit: cover;
          width: 100%;
        }
      }

      .author-and-date {
        margin-bottom: 1rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-width: 65%;

        @media (min-width: $grid-sm-min) {
          min-width: unset;
        }

        .author {
          font-weight: 500;
          margin-right: 0.5em;
        }

        .publish-date {
          color: $gray-500;
          text-transform: uppercase;
        }
      }

      .article-category {
        display: flex;
      }
    }

    .image-container {
      position: relative;
      flex: 1;
      overflow: hidden;
      background-color: $gray-200;
      width: 100%;
      padding-top: 50%;
      margin-bottom: 1rem;

      .image {
        position: absolute;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  .article-body {
    font-size: 1rem;
    img {
      max-width: 100%;
    }

    .news-article-column-wrapper {
      display: flex;

      .news-article-column {
        flex: 1;
        padding-right: 1em;
        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    p {
      text-align: left;
      margin-bottom: 2em;

      &.right {
        text-align: right;
      }
      &.center {
        text-align: center;
      }
      &.justify {
        text-align: justify;
      }
    }

  }
}

.secondary-action.dropdown-item {
  * {
    pointer-events: none;
  }

  > i {
    margin-right: 0.5em;
  }
}
