@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes coolToast {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }
  33% {
    transform: translateY(0);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backAndForth {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes backgroundScroll {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 350px;
  }
}

@keyframes dialogClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dialogOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    // margin-top: -15px;
  }
  100% {
    opacity: 1;
    // margin-top: 2px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    // margin-top: 2px;
  }
  100% {
    opacity: 0;
    // margin-top: -15px;
  }
}

@keyframes swimAway {
  0% {
    transform: translateY(0);
    opacity: 1;
    height: 14px;
    margin: 10px;
  }
  100% {
    transform: translateY(-6px);
    opacity: 0;
    height: 0;
    margin: 0;
  }
}

@keyframes deleteFade {
  0% {
    opacity: 1;
    height: 30px;
  }
  33% {
    opacity: 0.66;
    height: 25px;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes borderedJaw {
  0% {
    max-height: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    max-height: 1000px;
    border-top-width: 2px;
    border-bottom-width: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s ease-in-out infinite;
}
// @keyframes ripple {
//   0% {
//     box-shadow: 0px 0px 0px 1px transparent;
//   }
//   50% {
//     box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
//   }
//   100% {
//     box-shadow: 0px 0px 0px 15px transparent;
//   }
// }
