/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

@import 'z-indexes';
@import 'variables';
@import 'animations';

//Colors
// @import 'colors/default';
// @import 'colors/green';
// @import 'colors/megna';
// @import 'colors/purple';
// @import 'colors/red';
// @import 'colors/blue';
// @import 'colors/blue-dark';
// @import 'colors/default-dark';
// @import 'colors/green-dark';
// @import 'colors/red-dark';
// @import 'colors/megna-dark';
// @import 'colors/purple-dark';

// Import Bootstrap source files
@import '~bootstrap/scss/bootstrap.scss';

// This is for the icons
@import '~font-awesome/css/font-awesome.min.css';
@import '~simple-line-icons/dist/styles/simple-line-icons.css';
// @import 'weather-icons/css/weather-icons.min.css';
@import 'fonts/themify-icons/themify-icons';
@import 'fonts/display_fonts';

// Core files
@import 'core/core';
// @import 'widgets/widgets';
// @import 'responsive';
