.pnum-search {
  padding: 0 20px 10px 15px;

  .input-group {
    flex-wrap: nowrap;

    .Select {
      flex: 1;

      .pnum-search-Select__control {
        box-shadow: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .pnum-search-Select__indicators {
        margin-right: 24px;
      }

      .pnum-search-Select__indicator-separator,
      .pnum-search-Select__dropdown-indicator {
        display: none;
      }
    }

    .search-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // for hover and .show-sidebar (No hover on ipad)
  .collapsed-sidebar & {
    .search-field {
      opacity: 0;
    }
  }

  .collapsed-sidebar.show-sidebar & {
    .search-field {
      opacity: 1;
    }
  }
}

.portal-container {
  .pnum-search-Select__menu {
    width: unset;
    max-width: 80vw;
  }

  .pnum-search-Select__menu-notice.pnum-search-Select__menu-notice--no-options {
    text-align: left;
  }
}


.left-sidebar {
  // left variables
  $off-screen: -$sidebar-width;
  $collapsed: -($sidebar-width - $sidebar-width-collapsed);
  $full: 0;

  height: calc((var(--vh) * 100) - #{$top-bar-height});
  max-height: calc((var(--vh) * 100) - #{$top-bar-height});
  min-height: calc((var(--vh) * 100) - #{$top-bar-height});
  width: $sidebar-width;
  padding: 0;
  position: fixed;
  top: $top-bar-height;
  left: $off-screen;
  z-index: $zIndex-sideBar;
  background: $sidebar;
  box-shadow: $shadow;

  .animate-sidebar & {
    transition: left $sidebar-transition-speed ease, box-shadow $sidebar-transition-speed ease;
  }

  .search-field {
    transition: opacity $sidebar-transition-speed ease;
  }

  // (desktop) iPad landscape and higher sidebar rules
  @media (min-width: $grid-lg-min) {
    left: $collapsed;
    .search-field {
      opacity: 0;
    }

    .hover-sidebar &,
    .show-mobile-sidebar &,
    .show-sidebar & {
      left: $full;

      .search-field {
        opacity: 1;
      }
    }
  }

  // (mobile) iPad portrait and lower sidebar rules
  @media (max-width: $grid-md-max) {
    left: $off-screen;
    box-shadow: none;

    .show-mobile-sidebar &,
    .hover-sidebar & {
      left: $full;
      box-shadow: $shadow;
    }
  }

  .nav-text-box {
    background: #0180bf;
    padding: 13px 25px 13px 15px;
    border-bottom: 1px solid $border-color;
  }
}

// Page mask is only rendered in mobile mode
.page-mask {
  display: none;

  @media (max-width: $grid-md-max) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh) * 100);
    background-color: rgba(0, 0, 0, 0.25);
    pointer-events: none;
    z-index: $zIndex-pageMask;
    transition: opacity $sidebar-transition-speed ease;
    opacity: 0;

    .show-mobile-sidebar &,
    .hover-sidebar & {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.scroll-sidebar {
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  padding-bottom: 2rem;

  -ms-overflow-style: none;  // IE 10+
  scrollbar-width: none;  // Firefox
  &::-webkit-scrollbar {
    display: none;  // Safari and Chrome
  }
}

.collapse.in {
  display: block;
}

.sidebar-nav {
  padding: 1em 0 0 0px;

  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;

      &.project-finder-wrapper {
        border-bottom: 1px solid #e9ecef;
        padding-bottom: 1em;
      }

      a {
        color: $sidebar-text;
        padding: 10px 20px 10px 15px;
        display: block;
        align-items: center;
        position: relative;

        >i {
          margin-top: 3px;
          float: right;
          font-style: normal;
          width: 32px;
          text-align: center;
        }

        &.active,
        &:hover {
          color: $themecolor;

          >i {
            color: $themecolor;
          }
        }

        span i {
          margin-left: 0.5em;
        }
      }

      ul {
        padding: 10px 0px 10px 0px;
        background: rgba(0, 0, 0, 0.02);

        li a {
          padding: 7px 20px 7px 20px;
        }
        ul {
          padding-left: 15px;
        }
      }

      &.nav-small-cap {
        font-size: 12px;
        margin-bottom: 0px;
        margin: 20px 0px 20px 0px;
        color: $text-muted;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        font-weight: 500;
      }

      &.nav-devider {
        height: 1px;
        background: $border-color;
        display: block;
        margin: 15px 0;
      }
    }
  }

  > ul > li > a {
    i {
      font-size: 16px;
      margin-top: 3px;
      vertical-align: middle;

      color: $sidebar-icons;
    }
  }

  > ul > li {
    margin-bottom: 8px;
    margin-top: 8px;

    &.selected > a {
      color: $themecolor;
      border-left: 3px solid $themecolor;

      i {
        color: $themecolor;
      }
    }
  }

  .badge-count {
    position: absolute;
    top: 0;
    right: 1em;
    padding: 0.15em 0.25em;
    font-size: 0.75em;
    min-width: 1.25em;
    max-width: 3em;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    line-height: 1em;
  }

  .waves-effect {
    transition: none;
  }

  .control-button {
    display: block;
    padding: 20px;
    width: 73px;
    float: right;

    &:not(:disabled):not(.disabled).active {
      color: $themecolor;

      i {
        color: $themecolor;
      }
    }
  }
}

.sidebar-nav .has-arrow {
  position: relative;

  &::after {
    position: absolute;
    top: 21px;
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    transform: rotate(135deg) translate(0, -50%);
    transform-origin: top;
    transition: all $sidebar-transition-speed ease-out;
    content: '';
  }
}

.sidebar-nav ul ul li .has-arrow::after {
  top: 48%;
}

.sidebar-nav li.open > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded='true']::after {
  width: 7px;
  transform: rotate(225deg) translate(0, -50%);
}

.collapse-controls {
  width: 100%;
  padding: 10px 20px 10px 15px;

  button {
    float: right;
    color: $sidebar-icons;

    &:hover {
      color: $themecolor;
    }
  }
}
