.splash-page-action-buttons {
  display: block;

  .action-buttons-wrapper {
    .card-body {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 1em 0 0 1em;

      button {
        display: flex;
        justify-content: flex-start;
        max-width: calc(50% - 1em);
        width: calc(50% - 1em);
        align-items: center;
        margin-right: 1em;
        margin-bottom: 1em;

        &>span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &>i {
          font-size: 1.25em;
        }
      }
    }
  }
}
