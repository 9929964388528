.widget.collection-widget.contacts-widget.as-cards {
  .collection-widget-items-container {
    overflow: hidden;

    .collection-widget-items-inner {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .portal-form.ci-contact {
      position: relative;
      display: flex;
      flex-direction: column;

      min-height: 18em;
      min-width: 18em;
      width: 18em;
      max-width: 18em;
      flex-basis: 25%;
      margin: 0.5rem;
      border: 1px solid $gray-200;
      border-radius: 3px;

      background: $white;
      box-shadow: $shadow;
      white-space: normal;

      .field-container {
        width: auto !important;
      }

      .ci-contact-form-buttons {
        position: relative;
        padding: 0.5em;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .ci-contact-display {
        flex-grow: 1;

        .ci-image-container {
          width: 100%;
          height: 5em;
          background: $primary;
          // background: url('images/abstract-bg.jpg') center center no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .contact-name {
            font-size: 1.25em;
            span, a, a:active, a:visited {
              color: $white;
            }
          }

          .contact-type {
            margin-top: 0.25rem;
            font-size: 0.75em;
            text-transform: uppercase;
            color: $white;

            >i {
              margin-right: 0.5em;
            }
          }
        }

        .info-container {
          height: calc(100% - 5em);
          text-align: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;

          .contact-head {
            padding: 0.5em;
            min-height: 3em;

            .contact-company {
              margin-top: 0.5em;
              font-size: 1em;
            }

            .contact-role {
              font-size: 0.75em;
              text-transform: uppercase;
            }
          }

          .contact-details {
            padding: 0.5em;
            position: relative;

            >* {
              max-width: 100%;
              margin-bottom: 0.25em;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .contact-edit-fields {
          padding: 0.5em;
        }
      }
    }
  }
}
