.mini-project-summary {
  display: flex;
  flex-direction: column;

  .project-number {
    font-weight: 500;
  }

  .project-name,
  .client-name {
    white-space: nowrap;
  }
}
