$profile-column-count-xl: 4;
$profile-column-count-lg: 3;
$profile-column-count-md: 3;
$profile-column-count-sm: 2;
$profile-column-count-xs: 1;
$profile-column-margin: 1em;

.portal-user-profiles-header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $grid-sm-max) {
    flex-wrap: wrap;

    .page-titles .title-wrapper & {
      h4 {
        margin-bottom: 0.5rem;
      }
    }

    .search-wrapper {
      margin-bottom: 0.5rem;
    }
  }
}

.portal-user-profiles {
  .alpha-id-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    .profiles-alpha-header {
      margin: 0;
    }

    .alpha-toolbar {
      display: flex;
      flex-wrap: wrap;

      margin-left: 0.5rem;

      .separator {
        margin: 0 0.25em;

        &:last-of-type {
          display: none;
        }
      }
    }
  }

  .profiles-alpha-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: flex-start;

    margin-left: -0.5em;
    margin-right: -0.5em;

    .profile-card {
      margin-left: 0.5em;
      margin-right: 0.5em;
      margin-bottom: 1rem;
      flex-basis: calc(#{calc(100% / $profile-column-count-xl)} - 1em);
      background-color: $white;

      @media (max-width: $grid-lg-max) {
        flex-basis: calc(#{calc(100% / $profile-column-count-lg)} - 1em);
      }

      @media (max-width: $grid-md-max) {
        flex-basis: calc(#{calc(100% / $profile-column-count-md)} - 1em);
      }

      @media (max-width: $grid-sm-max) {
        flex-basis: calc(#{calc(100% / $profile-column-count-sm)} - 1em);
      }

      @media (max-width: $grid-xs-max) {
        flex-basis: calc(#{calc(100% / $profile-column-count-xs)} - 1em);
      }

      .inner-wrapper {
        width: 100%;
        padding-top: 150%;
        transform: scale(1);
        box-shadow: 0px 1px 2.97px 0.03px rgba(0, 0, 0, 0.23);
        transition: all 0.23s ease-out;
        cursor: pointer;

        &:hover {
          transform: scale(1.02);
          box-shadow: rgba(0, 0, 0, 0.23) 0 11px 29px 0;
        }

        .image-wrapper,
        .detail-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .detail-wrapper {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;

          .detail-card {
            background-color: $white;
            padding: 0.5rem;
            min-height: 5rem;
            position: relative;
            display: flex;
            flex-direction: column;
            color: $body-color;

            .detail-row {
              margin-bottom: 0.25rem;
              overflow: hidden;
              text-overflow: ellipsis;
              &:last-of-type {
                margin-bottom: 0;
              }
            }

            .user-name {
              font-size: 1.25em;
              font-weight: 400;
              color: $primary;
            }

            .separator {
              margin: 0 0.25em;
            }


          }
        }

        .image-wrapper {
          >* {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-position: center;
            background-size: cover;
          }

          .default-image {
            background: url
          }
        }
      }
    }
  }
}

input.search-users {
  border: 1px solid $gray-400;
  padding: 0 0.5em;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}
