.portal-modal {
  &.modal-dialog {
    @media (max-width: $grid-md-max) {
      width: auto;
      max-width: unset;
      margin: 1.75rem 0.5rem;
    }

    @media (max-width: $grid-xs-max) {
      margin: 0.5rem;
    }
  }

  .modal-content {
    border: none;
    border-radius: 0;
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.3);

    .modal-title {
      i+span {
        margin-left: 0.5em;
      }
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-footer {
      .buttons {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
  }
}
