/**
 *  Portal Data Table
 **/

@import './table/table-head.scss';
@import './table/table-cell.scss';
@import './table/table-row.scss';
@import './table/detail-row.scss';
@import './custom-filter-select.scss';

.portal-data-table {
  .page-size-select {
    width: 14em;
    .Select {
      min-width: 10em;
      display: block;
      border:0;
      padding: 0;
    }
  }

  // reduce padding on tabs on the tight tables
  .table-sm {
    .nav-tabs .nav-link {
      padding: 0.5rem;
    }
  }

  td.portal-data-field {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-sm th,
  .table-sm td {
    padding: 0.5rem 0.33rem 0.33rem;
    line-height: 1rem;
  }

  .table {
    opacity: 1;
    transition: opacity 0.125s ease-in, filter 0.125s ease-in;
    pointer-events: auto;
    width: calc(100% - 2px);
  }

  .busy {
    .table {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: $zIndex-fullScreenTable;
    margin: none;
    margin-bottom: 0;
  }

  .table-header-wrapper {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &>* {
      margin-bottom: 0.5em;
    }

    .title-wrapper{
      order: 0;
      flex-basis: 20%;
      flex-grow: 100;
      h4 {
        margin-bottom: 0;
      }
    }
    .search-controls-wrapper{
      order: 1;
      flex-grow: 100;
      flex-basis: 40%;
      display: flex;
      justify-content: flex-end;
      padding-left: 0.5em;
    }
    .display-controls-wrapper{
      order: 2;
      flex-basis: 0;
      display: flex;
      justify-content: flex-end;
      padding-left: 0.5em;
    }
    .column-manager-wrapper{
      order: 3;
      flex-basis: 100%;
    }
    .leading-results-text-wrapper{
      order: 4;
      flex-basis: 40%;
      max-width: 40%;
      height: 38px;
      display: flex;
      align-items: center;
    }
    .leading-pagination-wrapper{
      order: 5;
      flex-basis: 60%;
      max-width: 60%;
      display: flex;
      justify-content: flex-end;
    }

    // Large Viewport
    @media (max-width: $grid-lg-max) {
      .title-wrapper{
        max-width: 30%;
      }
    }

    @media (max-width: $grid-md-max) {
      .title-wrapper{
        max-width: 25%;
      }
    }

    // Small Viewport
    @media (max-width: $grid-sm-max) {
      .title-wrapper{
        max-width: 80%;
      }
      .leading-results-text-wrapper{
        display: none; // Hide the results
      }
      .leading-pagination-wrapper{
        display: none; // Hide the paginator
      }
      .search-controls-wrapper{
        padding-left: 0;
        flex-basis: 100%; // Grow the search bar to the full width of the viewport
        order: 2; // Swap the search controls and the display controls
      }
      .display-controls-wrapper{
        order: 1; // Swap the search controls and the display controls
      }
    }
  }

  .table-footer-wrapper {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    >* {
      margin-top: 0.5em;
    }

    .trailing-results-text-wrapper{
      order: 0;
      flex-basis: 0;
      flex-grow: 100;
      height: 38px;
      display: flex;
      align-items: center;
    }
    .page-size-select-wrapper {
      order: 1;
      flex-basis: 20%;
      display: flex;
      justify-content: flex-end;
      padding-right: 0.5em;
    }
    .trailing-pagination-wrapper{
      order: 2;
      flex-basis: 0%;
      max-width: 50%;
      display: flex;
      justify-content: flex-end;
    }

    // Large Viewport
    @media (max-width: $grid-lg-max) {
      .page-size-select-wrapper{
        order: 1;
        flex-basis: 40%;
        max-width: 40%;
        padding-right: 0;
        justify-content: flex-start;
      }
      .trailing-results-text-wrapper{
        order: 0;
        max-width: 100%;
        flex-basis: 100%;
        justify-content: center;
      }
      .trailing-pagination-wrapper{
        order: 2;
      }
    }

    // Small Viewport
    @media (max-width: $grid-sm-max) {
      .page-size-select-wrapper{
        order: 2;
        flex-basis: 100%;
        max-width: 100%;
        justify-content: center;
      }
      .trailing-results-text-wrapper{
        order: 0;
        flex-basis: 100%;
        max-width: 100%;
        justify-content: center;
      }
      .trailing-pagination-wrapper{
        order: 1;
        flex-basis: 100%;
        max-width: 100%;
        justify-content: center;
      }
    }
  }

  .table-display-controls {
    flex-wrap: nowrap;

    .btn-group{
      height: 38px; // to match the height of the other button controls

      >.btn.hide-in-mobile {
        // Hide the buttons in mobile viewports
        @media (max-width: $grid-sm-max) {
          display: none;
        }
      };

      &.close-button {
        margin-left: 0.5em;
      }
    }

    .mobile-mode-dropdown {
      display: none;
      >.btn {
        height: 38px; // to match the height of the other button controls
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      // Show the dropdown menu instead of the buttons in mobile viewports
      @media (max-width: $grid-sm-max) {
        display: inline-block;
        >.btn {
          display: inline-block;
        }
      }

      .dropdown-item {
        i {
          margin-right: 0.5em;
        }
      }
    }

  }

  .table-search-controls {
    // Stretch out the search controls in mobile viewports
    @media (max-width: $grid-sm-max) {
      width: 100%;

      .data-tables-filter {
        flex: 100;
      }
    }
    .data-tables-filter {
      input.search-primary:focus {
        border-color: lighten($primary, 20%);
      }
      input.search-secondary:focus {
        border-color: lighten($secondary, 20%);
      }
      input.search-success:focus {
        border-color: lighten($success, 20%);
      }
      input.search-info:focus {
        border-color: lighten($info, 20%);
      }
      input.search-warning:focus {
        border-color: lighten($warning, 20%);
      }
      input.search-danger:focus {
        border-color: lighten($danger, 20%);
      }
      input.search-light:focus {
        border-color: lighten($light, 20%);
      }
      input.search-cyan:focus {
        border-color: lighten($cyan, 20%);
      }
      input.search-dark:focus {
        border-color: lighten($dark, 20%);
      }
      input.search-purple:focus {
        border-color: lighten($purple, 20%);
      }
      input.search-green:focus {
        border-color: lighten($green, 20%);
      }
      input.search-mediumAqua:focus {
        border-color: lighten($mediumAqua, 20%);
      }
      input.search-blue:focus {
        border-color: lighten($blue, 20%);
      }
    }
  }

  .table-caption {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $gray-600;
  }

  .table-wrapper {
    position: relative;

    /* block in space when table is empty */
    background-color: #FAFAFA;

    /* reset to white for table */
    table {
      background-color: $white;
    }

    div.no-records {
      &:after {
        position: absolute;
        content: "Nothing to display";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        color: $gray-300;
      }
    }

    .overflow-indicator {
      position: absolute;
      pointer-events: none;
      width: 10px;
      top: 0;
      bottom: 0;

      opacity: 0; // Opacity is overridden at runtime based on the overflow display requirements

      &.left {
        left: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
      }

      &.right {
        right: 0;
        background: linear-gradient(to left, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
      }
    }
  }
}
