.splash-page-date-and-weather {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $gray-400;
  background-color: $white;
  box-shadow: $shadow;
  padding: 1em;

  // Small Viewport
  @media (max-width: $grid-sm-max) {
    padding: 1em;
  }

  h3 {
    color: $gray-600;
    margin: 0;
  }

  >:not(:last-child) {
    margin-right: 2em;
    @media (min-width: $grid-md-min) {
      margin-right: 1em;
    }
  }

  .date {
    .full,
    .short {
      display:none;
    }

    @media (min-width: $grid-md-min) {
      .full {
        display: block;
      }
    }
    @media (max-width: $grid-sm-max) {
      .short {
        display: block;
      }
    }
  }

  .current-weather {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .weather {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: right;

      .temperature {
        color: $gray-600;
        font-size: 1.5rem;
      }
    }

    img {
      // - h3 (currently displaying the celsius temperature) is in 1.5rem, and is scaled *1.2 by something
      // - body is 1em
      // image is placed alongside a h3 and body line stacked vertically,
      // and requires its max height to be set accordingly
      max-height: calc((1.5rem * 1.2) + 1em);
      margin-left: 0.5em;
    }
  }
}
