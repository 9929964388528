.full-page-loading-spinner {
  width: 100%;
  min-height: calc(50 * var(--vh));
  display: flex;
  align-items: center;
  justify-content: center;

  img.spinner {
    margin-right: 0.5em;
  }
}
