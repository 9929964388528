@mixin user-avatar-container($size: 35px) {
  border-radius: 50%;
  width: $size;
  height: $size;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  .user-avatar {
    background: $gray-500;
    width: $size;
    display: block;
  }
}

@mixin striped-children($odd: $white, $even: $gray-100) {
  &:nth-child(odd) {
    background: $white;
  }
  &:nth-child(even) {
    background: $gray-100;
  }
}
