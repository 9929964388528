.news-list-page {
  &.container-fluid {
    padding-bottom: 1.25rem;
  }

  .news-header {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    box-shadow: $shadow;
    padding-bottom: calc(1.25rem - 0.5em);

    @media (max-width: $grid-md-max) {
      padding-bottom: calc(0.75rem - 0.5em);
    }

    >* {
      margin-bottom: 0.5em;
    }

    .title-wrapper{
      order: 0;
      flex-basis: 20%;
      flex-grow: 100;
    }
    .category-filter-wrapper {
      order: 1;
      flex-basis: 20%;
      flex-grow: 100;
      display: flex;
      justify-content: flex-end;
      .category-picker {
        display: block;
      }
    }
    .search-controls-wrapper{
      order: 2;
      flex-grow: 100;
      flex-basis: 45%;
      display: flex;
      justify-content: flex-end;
      padding-left: 0.5em;
      min-width: 9em;

      .news-list-page-search-controls{
        @media (max-width: $grid-sm-max) {
          flex: 1;
        }

        input.search-input {
          background-image: linear-gradient($themecolor, $themecolor), linear-gradient($border-color, $border-color);
          background-size: 0 2px, 100% 1px;
          background-repeat: no-repeat;
          background-position: center bottom, center calc(100% - 1px);
          background-color: transparent;
          transition: background 0s ease-out;
          float: none;
          box-shadow: none;
          border-color: $gray-300;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          &:focus {
            outline: none;
            background-image: linear-gradient($themecolor, $themecolor), linear-gradient($border-color, $border-color);
            background-size: 100% 2px, 100% 1px;
            box-shadow: none;
            transition-duration: 0.3s;
          }
        }
      }
    }
    .sort-controls-wrapper{
      order: 2;
      flex-basis: 0;
      display: flex;
      justify-content: flex-end;
      padding-left: 0.5em;
    }
    .leading-results-text-wrapper{
      order: 4;
      flex-basis: 40%;
      max-width: 40%;
      height: 38px;
      display: flex;
      align-items: center;
    }
    .leading-pagination-wrapper{
      order: 5;
      flex-basis: 60%;
      max-width: 60%;
      display: flex;
      justify-content: flex-end;
    }


    // Large Viewport
    @media (max-width: $grid-lg-max) {
      .title-wrapper{
        max-width: 30%;
      }
    }

    // Small Viewport
    @media (max-width: $grid-sm-max) {
      .title-wrapper{
        max-width: 80%;
      }
      .leading-results-text-wrapper{
        display: none; // Hide the results
      }
      .leading-pagination-wrapper{
        display: none; // Hide the paginator
      }
      .search-controls-wrapper{
        padding-left: 0;
        flex-basis: 50%; // Grow the search bar to half width of the viewport
      }
    }

    // XS Viewport
    @media (max-width: $grid-xs-max) {
      .search-controls-wrapper{
        flex-basis: 100%; // Grow the search bar to the full width of the viewport
      }
    }
  }

  .news-article-teaser-list-wrapper {
    &.loading {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .news-footer {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    >* {
      margin-top: 0.5em;
    }

    .trailing-results-text-wrapper{
      order: 0;
      flex-basis: 0;
      flex-grow: 100;
      height: 38px;
      display: flex;
      align-items: center;
    }
    .page-size-select-wrapper {
      order: 1;
      flex-basis: 20%;
      display: flex;
      justify-content: flex-end;
      padding-right: 0.5em;
    }
    .trailing-pagination-wrapper{
      order: 2;
      flex-basis: 0%;
      max-width: 50%;
      display: flex;
      justify-content: flex-end;
    }

    // Large Viewport
    @media (max-width: $grid-lg-max) {
      .page-size-select-wrapper{
        order: 2;
        flex-basis: 100%;
        padding-right: 0;
      }
      .trailing-results-text-wrapper{
        order: 0;
        max-width: 40%;
        flex-basis: 40%;
      }
      .trailing-pagination-wrapper{
        order: 1;
      }
    }

    // Small Viewport
    @media (max-width: $grid-sm-max) {
      .page-size-select-wrapper{
        order: 2;
        justify-content: center;
      }
      .trailing-results-text-wrapper{
        order: 1;
        flex-basis: 100%;
        max-width: 100%;
        justify-content: center;
      }
      .trailing-pagination-wrapper{
        order: 0;
        flex-basis: 100%;
        max-width: 100%;
        justify-content: center;
      }
    }
  }

  .page-size-select {
    width: 13em;
    .Select {
      display: block;
      border:0;
      padding: 0;
    }
  }
}
