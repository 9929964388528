.portal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: $zIndex-reactPortal;

  @media (max-width: $grid-lg-max) {
    z-index: $zIndex-mobileMode-reactPortal;
  }

  &.sidebar {
    position: fixed;
    right: unset;
    width: 100vw;
  }

  >* {
    pointer-events: all;
  }

  // When the full screen table is open, move the portal above the full screen table
  .has-full-screen-table & {
    z-index: $zIndex-fullScreenTable-reactPortal;
  }

  // When the reactscrap modal is open, move the portal above the modal
  .modal-open & {
    z-index: $zIndex-modalOpen-reactPortal;
  }
}
