.portal-form.file {
  width: 7rem;
  max-width: 7rem;
  min-width: 7rem;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  margin: 1rem 0 0 1rem;

  .loading-spinner img.spinner {
    margin: 0;
  }

  &.is-clickable {
    cursor: pointer;
  }

  .icon-wrapper {
    height: 7rem;
    width: 7rem;
    position: relative;
    background-color: $white;
    border-style: solid;
    border-color: $border-color;
    border-width: $border-width;
    border-radius: $border-radius;
    box-shadow: $shadow;

    .clickable-icon,
    .fallback-icon {
      width: 7rem;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 4rem;
      }

      &.error {
        color: $danger;
      }
    }

    >.upload-progress {
      background-color: $primary;
      opacity: 0.5;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1em;
      width: 0;
      transition: width 0.1s ease;
    }

    // This image isn't really displayed, it simply loads the image to be cached
    img.thumbnail-image.loader {
      display:none;
    }

    // This is the real image that is displayed after the <image /> does the hard work of loading the image
    div.thumbnail-image {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-size: cover;
      animation: fadeIn 0.5s ease forwards;
    }

    .info-tooltip-wrapper {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
    }
  }

  .filename-wrapper {
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 0.25rem 0.5rem 0;

    .filename {
      display: block;
      line-height: 1.25em;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-break: break-word;

      >span {
        white-space: nowrap;
      }
    }

    .file-size {
      display: block;
      font-size: 0.6rem;
      color: $gray-500;
      margin-top: 0.25em;
    }
  }

  .busy-indicator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: 7rem;
    background-color: transparentize($black, 0.9);
  }

  .buttons-wrapper {
    display: none;

    position: absolute;
    justify-content: flex-end;
    bottom: 0.2rem;
    left: 0;
    right: 0.2rem;

    .form-buttons {
      button+button {
        margin-left: 0.25em;
      }
    }
  }

  &:hover {
    .buttons-wrapper {
      display: flex;
    }
  }
}
