.portal-pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0;
  // Swap the results and the paginator in mobile viewports
  @media (max-width: $grid-sm-max) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .pagination {
    margin-bottom: 0;
  }

  &.pagination-primary {
    .page-item:not(.disabled) a {
      color: $primary;
    }
    .page-item.active a {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &.pagination-secondary {
    .page-item:not(.disabled) a {
      color: $secondary;
    }
    .page-item.active a {
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  &.pagination-success {
    .page-item:not(.disabled) a {
      color: $success;
    }
    .page-item.active a {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  }

  &.pagination-success {
    .page-item:not(.disabled) a {
      color: $success;
    }
    .page-item.active a {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  }

  &.pagination-info {
    .page-item:not(.disabled) a {
      color: $info;
    }
    .page-item.active a {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  }

  &.pagination-warning {
    .page-item:not(.disabled) a {
      color: $dark;
    }
    .page-item.active a {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  }

  &.pagination-danger {
    .page-item:not(.disabled) a {
      color: $danger;
    }
    .page-item.active a {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  }

  &.pagination-dark {
    .page-item:not(.disabled) a {
      color: $dark;
    }
    .page-item.active a {
      color: $white;
      background-color: $dark;
      border-color: $dark;
    }
  }

  .page-links {
    .page-item {
      flex-grow: 1;
      flex-shrink: 0.75;
      text-align: center;

      &.prev,
      &.next {
        // Hide the prev and next links in mobile modes
        @media (max-width: $grid-xs-max) {
          display: none;
        }
      }

      .page-link {
        white-space: nowrap;
        z-index: unset;
      }
    }

    // Stretch the paginator in mobile mode
    @media (max-width: $grid-xs-max) {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .results-text {
    @media (max-width: $grid-sm-max) {
      padding: 15px 0 0;
    }
    @media (max-width: $grid-xs-max) {
      padding: 15px 0 0;
      text-align: center;
      width: 100%;
    }
  }
}
