.file-drop-target {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;

  &.dragging-file-over {
    z-index: 1;
  }

  .drop-zone-indicator {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    bottom: 0.5em;
    left: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    pointer-events: none;

    &.dragging {
      background-color: transparentize($white, 0.25);
      color: $primary;
      border: 1px dashed $primary;
      z-index: 1;
    }

    &.invalid {
      border-color: $danger;
      color: $danger;
    }

    span {
      user-select: none;
      margin-left: 0.5em;
    }
  }
}
