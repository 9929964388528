// Note: there is a constant in the info-tooltip.jsx that must match the animation speed
$animationSpeed: 0.1s;

.info-tooltip-wrapper {
  &>button {
    padding: 0;
    color: $text-muted;

    &:hover, &.active {
      color: $text-info;
    }
  }
}

span+.info-tooltip-wrapper {
  margin-left: 0.5em;
}

.info-tooltip {

  &.open {
    animation: fadeIn $animationSpeed ease 1 both forwards;
  }

  &.closing {
    animation: fadeOut $animationSpeed ease 1 both forwards;
  }

  .popover-inner {
    // This is a different shadow than the global shadow
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  }

  .popover {
    max-width: 50vw;
  }
}
