$full-page-offset: 16.5rem;
$min-full-page-height: 16rem;
$min-embedded-height: 32rem;

.widget.company-locations {
  overflow: hidden;

  // When displayed on a standalone record detail page
  .record-detail-page & {
    min-height: $min-full-page-height;
    height: $min-full-page-height;
    max-height: 32rem;

    @media screen and (min-height: 32rem) {
      height: calc((100 * var(--vh)) - #{$full-page-offset});
      max-height: calc((100 * var(--vh)) - #{$full-page-offset});
    }
  }

  // When displayed inside a portal data table
  .portal-data-table & {
    min-height: $min-embedded-height;
    height: $min-embedded-height;
    max-height: $min-embedded-height;
  }

  .split-view {
    .split-view-container {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .selected-record-detail {
    background-color: $white;
    position: relative;
    max-height: 100%;

    // Most of this is copied from _row-details-widget.scss
    // Some of it has been customised to change the way the form scrolls
    // below the header
    .portal-form.vertical-layout {
      display: flex;
      flex-direction: column;
      max-height: 100%;
      position: relative;
      max-width: unset !important;

      .details-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        background: $themecolor-dark;
        color: $white;
        padding: 0.25em 0.5em 0.25em 0.75em;

        .btn {
          margin-bottom: 0
        }

        .panel-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      &.company-location {
        .google-map-wrapper {
          margin: -10px -10px 10px;
        }
      }

      .form-horizontal {
        padding: 10px;
        flex: 1;
        overflow: auto;

        @media all and (min-width: $grid-lg-min) {
          .field-container {
            max-width: 50vw;
          }
        }
      }
    }

    .selected-node-count {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: $gray-500;
    }

    .company-space-detail {
      .no-contracts {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5em;
      }

      .imt-wrapper {
        padding: 0.5rem;
        position: relative;
      }
    }
  }
}
