.portal-google-map {
  position: relative;

  .spinner-wrapper,
  &.fill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map-container{
    width: 100%;
    height: 100%;
  }
}

.pac-container {
  z-index: 2000;
}
