.modal-wizard-page-indicator {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $grid-xs-max) {
    display: none;
  }

  .page-dot-wrapper {
    display: grid;
    column-gap: 2em;

    .page-dot {
      height: 0.5em;
      width: 0.5em;
      display: block;
      content: "";
      background-color: $gray-400;
      border-radius: 50%;

      &.active {
        background-color: $gray-800;
      }
    }
  }
}
