.portal-modal {
  &.modal-confirm {
    .reason-wrapper {

      input.form-control-danger {
        border-color: $danger;
      }

      .reason-error {
        margin-top: 0.5em;
        font-size: 0.8em;
        color: $danger;
      }
    }
  }
}
