/**
 * The row with the widgets in it
 */
.portal-data-table {
  .detail-row {
    .datatable-detail-contents {
      padding: 0;

      .project {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }

    .detail-column {
      background: #f3f3f3;
      position: relative;
    }
  }
}
