.progress-percent-viewer {
  line-height: 21px;
}

// can't apply here, added to _poly-form
// .portal-form .vertical-layout .progress-percent-editor {
//   line-height: 25px;
// }


.progress-percent-editor,
.progress-percent-viewer {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  white-space: nowrap;

  .progress-percent-bar {
    background: $primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 24px;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    background-color: #0078ad;
    transition: width 0.6s ease;
  }

  .progress-percent-blank {
    background: $gray-200;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    justify-content: center;
    transition: width 0.6s ease;
    min-height: 24px;
  }
}

.progress-percent-editor {
  .progress-percent-bar,
  .progress-percent-blank {
      padding: calc(0.135rem + 1px) 0 0.125rem 0;
  }

  &:hover {
    cursor: pointer;
  }
}
