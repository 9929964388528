.portal-notifications {
  padding: 0;

  .notification {
    cursor: default;
  }

  .portal-notifications.container &{
    margin-left: 1em;
    margin-right: 1em;
  }

  &.card {
    margin-top: 1em;
    margin-bottom: 0;
  }

  .notification  {
    margin-top: 0;
    box-shadow: none;


    h4.title {
      font-size: 1em;
      line-height: 1.2em;
      font-weight: bold;
      margin: 0 0 5px 0;
    }
  }
}
