.pmldd {

  ul.pmldd-menu {
    position: absolute;
    top: 0;
    min-width: 10rem;
    padding: 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: $body-color;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-300;
    border-radius: 0.25rem;
    box-shadow: $shadow;

    &.sub-menu {
      top: -0.125rem;
    }
  }

  li.pmldd-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 400;
    color: $body-color;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    i {
      line-height: 1.5em;
    }

    i+span, span+i{
      margin-left: 0.75em;
    }

    &:hover, &:focus {
      color: $body-color;
      text-decoration: none;
      background-color: $gray-100;
    }

    &.active, &:active {
      color: $white;
      text-decoration: none;
      background-color: $primary;
    }

    &.disabled {
      color: $text-muted;
      cursor: default;

      &.active, &:active,
      &:hover, &:focus {
        color: $text-muted;
        background-color: transparent;
      }
    }

    &.sub-menu {
      // This is the arrow that indicates there is a sub-menu
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -0.3rem;
        width: 0;
        height: 0;
        border-top: 0.3rem solid transparent;
        border-bottom: 0.3rem solid transparent;
      }
    }
  }

  li.pmldd-separator {
    height: 0.25rem;
    margin-bottom: 0.25rem;
    border-bottom: 1pt solid $gray-300;

    // Hide separator lines that end up at the top and button of the menu due to dynamically hidden items
    &:first-child,
    &:last-child,
    // As do separator lines that are next to each other
    &+li.pmldd-separator {
        display: none;
    }
  }

  // Customisations when the drop-down is right aligned (i.e. expanding to the left)
  &.right {
    ul.pmldd-menu {
      right: 0;

      // When there is a sub-menu amongst the children, make sure all sub-menu items have padding to accommodate the arrow
      &.has-sub-menu-item {
        & > li.pmldd-item {
          padding-left: 2.5rem;
        }
      }

      &.sub-menu {
        right: 0.125rem;
      }
    }


    li.pmldd-item.sub-menu {
      &::after {
        left: 1rem;
        border-right: 0.45rem solid $body-color;
        border-left: none !important;
      }

      &.active, &:active {
        &::after {
          border-right-color: $white;
        }
      }
    }
  }

  // Customisations when the drop-down is left aligned (i.e. expanding to the right)
  &.left {
    ul.pmldd-menu {
      left: 0;

      // When there is a sub-menu amongst the children, make sure all sub-menu items have padding to accommodate the arrow
      &.has-sub-menu-item {
        & > li.pmldd-item {
          padding-right: 2.5rem;
        }
      }

      &.sub-menu {
        left: 0.125rem;
      }
    }


    li.pmldd-item.sub-menu {
      &::after {
        right: 1rem;
        border-left: 0.45rem solid $body-color;
        border-right: none !important;
      }

      &.active, &:active {
        &::after {
          border-left-color: $white;
        }
      }
    }
  }
}
