.widget.collection-widget.project-resources-widget {
  .actions {
    .action {
      display: flex;
      align-items: center;
      padding: 0.5em 0.5em 0.5em 0;
    }

    .teams-logo {
      display: flex;
      height: 100%;
      padding-right: 0.5em;
      margin-right: 0.5em;
      border-right: 1px solid $gray-200;
      justify-content: flex-end;
      align-items: center;

      img {
        height: 2em;
      }
    }
  }

  &+.alert {
    margin-bottom: 0;
  }
}
