.record-detail-page {
  .record-detail-view {
    background: none;

    .record-tab-container{
      margin: 0;

      ul.customtab {
        background: none;
      }

      .tab-content {
        background: $gray-100;
      }
    }
  }
}
