.widget.row-details-widget {
  background: $white;
  white-space: normal;
  height: 100%;

  .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    background: $themecolor-dark;
    color: $white;
    padding: 0.25em 0.5em 0.25em 0.75em;

    .btn {
      margin-bottom: 0
    }

    .panel-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .form-horizontal {
    padding: 10px;
  }
}
