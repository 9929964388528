.pr {
  // max-width: 66vw;

  pre {
    background: white;
    color: #5e5e5e;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    padding: 4px;

    &.dark {
      background: #23241e;
      color: #b5b5b5;
    }
  }

  .item {
    width: 100%;
  }

  .html {
    .string {
      color: #d6ae0a;
    }
    .number {
      color: #d2711d;
    }
    .boolean {
      color: #ff0066;

      // &.true {
      //   // color: #ff00e1;
      // }

      &.false {
        color: #a10f49;
      }
    }
    .null {
      color: #af6fff;
    }
    .undefined {
      color: #602ba0;
    }
    .key {
      color: #3193ff;
    }
    .function {
      color: #64a227;
    }
  }

  hr {
    border-color: gray;
  }

  .accordion {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease-in-out 0.2s;

    &.open {
      overflow: scroll;
      max-height: 660px;
    }
  }

  .heading {
    width: 100%;
    font-family: 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New',
      monospace;
    line-height: 1;
    padding: 5px;
    margin: 0;
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    overflow: hidden;
    font-weight: 600;
    color: #5d0e00;
    background: #fff0b3;

    .type {
      font-style: italic;
      font-weight: 300;
    }
  }

  .controls {
    margin-left: 7px;
  }

  &.fly {
    position: fixed;
    top: 8px;
    left: 8px;
    z-index: 800;
    opacity: 0.95;
    max-width: 670px;
    width: auto;

    .accordion {
      &.open {
        box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.3);
      }
    }

    .heading {
      width: auto;
      box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.3);
      opacity: 0.2;
      transition: opacity 2.5s;
      background: white;
      padding: 5px;
      font-size: 14px;
      cursor: grab;

      &:hover {
        transition: opacity 0.5s;
        opacity: 0.95;
      }
    }

    &.open {
      width: auto;

      .heading {
        transition: opacity 0.5s;
        opacity: 0.95;
      }
    }
  }
}
