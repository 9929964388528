.collection-widget .collection-widget-items-container {
  overflow-x: auto;
  // Check this this with .record-detail-view max height of 420px
  min-height: 332px;

  .collection-widget-items-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    pointer-events: none;

    >* {
      flex: 1;
      pointer-events: all;
    }

    .form-buttons {
      display: inline-block;
      white-space: nowrap;
    }
  }

  .btn.btn-outline {
    border: 0;
  }

  .loading {
    padding: 10px;

    .btn-loading {
      display: inline-block;
      line-height: 32px;
      width: 52px;
    }
  }

  .load-more {
    margin-left: 1em;
    line-height: 2em;
    margin-bottom: 1em;
  }

  .Select.is-disabled {
    .Select-control {
      background-color: #f9f9f9;
    }
  }

  .Select.form-control {
    width: 100%;
    vertical-align: top;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    min-height: auto;
    padding: 4px 8px 3px;

    .Select__control,
    .Select-value,
    .Select-placeholder,
    .Select__value-container,
    .Select__value-container>* {
      border: 0;
      margin: 0;
      padding: 0;
      min-height: 21px;
    }

    .Select__single-value {
      transform: none;
      top: 0;
    }

    .Select__value-container {
      padding: 0;
    }
    .Select__indicator {
      padding: 0 0 0 8px;
    }

    .Select__indicator-separator {
      margin: 0;
    }
  }

  .datatable-inline-editor {
    .datatable-widget-line {
      display: table-row;
      white-space: nowrap;

      // Loading indicator under fields
      .feedback-footer {
        height: 0;
        transition: height 0.33s;
        // animation: backAndForth 4s ease-in-out infinite;
        animation: backgroundScroll 0.66s linear infinite normal;
        background: #3e67b4;
        background-image: linear-gradient(90deg, #3e67b4, #b4d9ff, #b4d9ff, #3e67b4, #3e67b4);
        background-size: 350px 350px;

        &.active {
          height: 3px;
        }

        &.has-success {
          background: #149443;
          background-image: linear-gradient(90deg, #149443, #abf79c, #abf79c, #149443, #149443);
          background-size: 350px 350px;
        }
      }

      &.editing {
        .field-container .portal-data-field.not-editable.inline {
          background: $gray-100;
        }
      }

      .field-container {
        position: relative;
        display: inline-block;
        background: white;
        border: 1px solid #fff;
        border-radius: 0;
        border-bottom-color: #eee;
        border-right: 1px solid #eee;

        &.has-danger {
          border-bottom-color: rgb(146, 17, 17);
          background-color: #ffefef;

          input,
          .Select-value-label {
            color: rgb(146, 17, 17);
          }
        }

        &.hidden {
          display: none;
        }

        .portal-data-field {
          display: block;
          &.header.inline {
            background: $gray-100;
            cursor: default;

            i {
              margin-right: 0.5em;
            }
          }

          &.disabled {
            color: $gray-600;
          }
        }

        &.long-field {
          .inline {
            width: 300px;
          }
        }

        &.text-only {
          border: none;
          background: transparent;

          .portal-data-field,
          .inline {
            background: transparent;

            &.not-editable.inline,
            &.disabled {
              background: transparent;
            }
          }
        }
      }

      /**
       * these are a hack because DayPickerInput below is inexplicably
       */
      // max-height: 30px;
      // overflow: hidden;
      & > input,
      & > div,
      & > span {
        vertical-align: top;
      }

      .inline {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        outline: none;
        border: none;
        padding: 2px 8px;
        min-height: 28px;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
        // width: 160px;
        width: 100%;
        background: transparent;

        &:disabled {
          background-color: #f9f9f9;
        }

        &.is-open {
          overflow: visible;
        }
      }

      .date-input {
        input {
          border: none;
          outline: none;
          min-height: 28px;
          padding: 3px 8px;
          background: transparent;
          text-align: left;
          border: none;

          &:disabled {
            background-color: #f9f9f9;
          }
        }
      }

      .panel {
        margin-bottom: 0;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #efefef;

        .widget-comments-item {
          padding: 10px 5px 10px 64px;
        }

        .widget-comments-header {
          color: #cacaca;
        }

        .widget-comments-avatar {
          border-radius: 50%;
          background: #efefef;
          width: 35px;
          height: 35px;
          margin-left: -52px;
        }
      }
    }

    .btn.btn-sm {
      border-radius: 0;
      padding: 5px 7px;
      width: 30px;
      min-width: 30px;
      border-right: none;
      border-top: 1px solid $gray-100;

      &:last-of-type {
        border-right: 1px solid $gray-300;
      }
    }

    .batch-item {
      padding: 0;

      .btn.btn-sm,
      .btn.btn-sm:last-of-type {
        width: 100%;
        padding: 0px;
        height: 22px;
        border-radius: 0.2rem;
        border-right: 0;
        border-top: none;
      }
    }
  }
}
