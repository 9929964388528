// Project Card for Card View

$project-card-border: 1px solid $gray-200;

.project-card-row {

  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media (max-width: $grid-sm-max) {
    flex-wrap: wrap;
    .project-card-panel {
      flex-grow: 1;
    }
    .priority-status {
      order: 1;
    }
    .summary-box {
      order: 2;
    }
    .detail-body {
      order: 3;
    }
  }

  .priority-status {
    border: $project-card-border;
    min-width: 8rem;
    display: flex;
    align-items: center;
    .badge {
      margin-bottom: 1.5rem;
    }
  }

  .detail-body {
    border-top: $project-card-border;
    border-bottom: $project-card-border;
    .detail-buttonbar {
      margin-top: 1rem;
    }
    flex-grow: 2;

    .card-text {
      &>a {
        word-break: break-all;
      }
    }
  }

  .summary-box {
    min-width: 20rem;
    border: $project-card-border;

    .detail-title {
      text-align: center;
      font-weight: 100;
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
    }
    .detail-left, .detail-right {
      display: inline-block;
      width: 50%;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .detail-left {
      font-weight: 500;
    }
    .detail-right {
      text-align: right;
    }
  }
}
