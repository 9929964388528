.profile {
  position: relative;

  .user-avatar-container {
    width: 100%;

    img {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
  }
}