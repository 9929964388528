.ptv-search-bar {
  display: flex;
  flex: 1;
  .table-search-controls {
    flex: 1;
    .data-tables-filter {
      flex: 1;
    }
    button {
      flex: 0;
    }
  }
}
