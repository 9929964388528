.widget.project-drive-widget {
  padding: 1em;
  white-space: normal;

  .project-drive-heading {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5em;

    img {
      height: 1.5em;
      padding-right: 0.5em;
    }
  }
}
