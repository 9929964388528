/**
 *  Global Ci CSS variables
 */

$ci-blue: #0078ad;

$ci-log-dark: #0927ba;
$ci-log-medium: #0a5ac4;
$ci-log-bright: #0abcc4;
$ci-log-turquoise: #09ba90;

$ci-mono-bright: #4bc4f9;
$ci-mono-light: #00adf9;
$ci-mono-pale: #3893bb;
$ci-mono-dull: #00557a;

$ci-compliment-light: #19b5f9;
$ci-compliment-dark: #004360;
$ci-compliment-brown: #2c2c2e;
$ci-compliment-orange: #ad5d00;

$ci-compound-dark: #1c2447;
$ci-compound-strong: #1c2447;
$ci-compound-orange: #b7642e;
$ci-compound-red: #e04816;

/**
 * Import the theme pack.
 *
 * Includes:
 *  - bootstrap
 *  - font-awesome
 *  - themeify-fonts
 */
@import './scss/style.scss';

/**
 * Module overrides
 */
@import './scss/module-overrides/module-overrides.scss';

/**
 * Import our component styles
 */
@import './scss/portal-mixins/mixins';
@import './scss/components/portal-components';

/**
 * Responsive overrides
 */
@import './scss/responsive';
