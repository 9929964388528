.fusioncharts-container [class^="raphael-group-"][class*="-plot-group"] rect,
.fusioncharts-container [class^="raphael-group-"][class*="-plot-group"] path {
  cursor: pointer;
}

// Hack out the "Fusioncharts Trial" text
.fusioncharts-container {
  &> svg {
    &> g[class^="raphael-group-"] {
      text {
        display: none;
      }

      > g {
        text {
          display: inherit;
        }
      }
    }
  }
}

.portal-fusion-chart {
  position: relative;
  min-height: 25em;
  overflow: hidden;

  .loading-indicator{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-data {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-200;
  }

  >div {
    height: 100%;
  }
}

// Semantic CSS for each chart type
