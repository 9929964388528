.preloader {
  .preloader-message {
    text-align: center;
    padding: 50px;
  }
}

.alert {
  border-radius: 0;
}

a.nav-link {
  cursor: pointer;
}

// Breadcrumb
.page-titles .breadcrumb .breadcrumb-item.active {
  color: $themecolor;
}

// Min height for form elements
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  min-height: 28px;
}

.btn-transparent {
  background: transparent !important;
}

// Fix height issues on inline form inputs bootstrap adds
.form-control {
  height: auto;

  &::placeholder {
    color: $input-placeholder-color
  }
}


// This was implemented to fix the vertical alignment of the checkbox
// when in a dropdown, so this MAY break what it looks like in an inline form.
// No test case to go on
.form-check-input {
  margin-top: 0;
  margin-left: -1.3rem;
  height: 1.5em;
  width: 1.5em;
}

/**
 * Add global page / document / app SCSS here
 */

.highlight-text {
  // color: $white;
  background-color: transparentize($color: $yellow, $amount: 0.7);
}

.portal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: +1;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.btn-primary,
.btn-outline-primary {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-warning,
.btn-outline-warning {
  color: #623b17;

  &:hover {
    color: #623b17;
  }
}

.btn-secondary {
  border-color: $btn-secondary-border;
}

.btn-outline-secondary {
  color: $body-color;
  background: $white;
  border-color: $btn-secondary-border;

  &:hover {
    border-color: $gray-400;
  }
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 5px;

  &:last-of-type {
    margin-right: 0;
  }
}
