.splash-page-project-summary {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
    }
  }

  .card-body {
    padding: 0;
  }

  .error {
    padding: 1em;
    color: $red;
    text-align: center;
  }

  .project-group {
    .project-group-title {
      padding: 0.5em 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ddf1fd;
      font-weight: 500;

      .title-and-tooltip {
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .title {
          margin: 0 0.5em 0 0;
        }
      }

      a {
        color: $gray-600 !important;
      }
    }

    .project-group-content {
      padding: 1em;

      .project {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin-bottom: 1em;

        .rank {
          flex: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center; 

          .rank-number{
            font-size: 1.25em;
            font-weight: 300;
            color: $gray-500;
          }

          .avatar-wrapper {
            border-radius: 100%;
            width: 2em;
            height: 2em;
            overflow: hidden;
            display: inline-flex;
            justify-content: space-around;
            align-items: flex-start;
            margin-right: 0.5em;
        
            .user-avatar {
              background: $white;
              object-fit: cover;
              width: 100%;
            }
          }
        }

        .details {
          flex: 1;

          .p-number-and-value {
            display: flex;
            justify-content: space-between;

            .project-number {
              font-size: 1.25em;

              img {
                width: 1em;
                height: 1em;
              }
            }

            .value {
              font-weight: 500;
            }
          }

          .phase {
            text-transform: uppercase;
            background-color: $gray-500;
            display: inline-block;
            padding: 0.1em 0.5em;
            color: $white;
            font-size: 0.75em;
            border-radius: 0.5em;
          }
        }
      }
    }
  }
}