/*
 * Template Name: Admin Template
 * Author: Wrappixel
 */

/* ==============================================================
  Small Desktop & above
 ============================================================== */

// iPad landscape and larger
@media (min-width: $grid-lg-min) {
  // left variables
  $full-sidebar: $sidebar-width;
  $collapsed-sidebar: $sidebar-width-collapsed;
  $no-sidebar: 0;

  // .page-wrapper Pairs with sidebar rules in sidebar.scss,
  // please keep these in sync
  .page-wrapper {
    margin-left: $collapsed-sidebar;

    .show-sidebar & {
      margin-left: $full-sidebar;
    }
  }

  body {
    &.show-sidebar {
      --max-page-width: calc(100% - #{$full-sidebar});
    }
  }
}

// iPad portrait and lower
@media (max-width: $grid-md-max) {
  .card-body {
    padding: 0.75rem;
  }

  // TODO this seems wrong and bad
  .d-flex {
    display: block !important;

    &.no-block {
      display: flex !important;
    }
  }

  // Header
  .topbar {
    .top-navbar {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      flex-wrap: nowrap;
      -webkit-align-items: center;

      .navbar-collapse {
        display: flex;
        width: 100%;
      }

      .navbar-nav {
        flex-direction: row;

        > .nav-item.show {
          position: static;

          .dropdown-menu {
            width: 100%;
            margin-top: 0px;
          }
        }

        .dropdown-menu {
          position: absolute;

          .with-arrow {
            display: none;
          }
        }
      }
    }
  }
}

/* ==============================================================
 Ipad & above all (768px)
 ============================================================== */

// iPad portrait and higher
@media (min-width: $grid-md-min) {
  .material-icon-list-demo .icons div {
    width: 33%;
    padding: 15px;
    display: inline-block;
    line-height: 40px;
  }
}

/* ==============================================================
 Phone and below ipad (767px)
 ============================================================== */

// Phone size
@media (max-width: $grid-sm-max) {
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }

  /*Footer*/
  .material-icon-list-demo .icons div {
    width: 100%;
  }

  .dataTables_wrapper {
    .dataTables_paginate {
      width: 100%;

      .paginate_button {
        padding: 0.5rem 0.6rem;
      }
    }
  }
}
