
.gantt-view {
  .view-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .gantt-scroll-container {
    overflow-x: scroll;
    white-space: nowrap;

    .gantt-chart {
      background: white;
      overflow-x: hidden;

      .day-titles {
          height: 2.6rem;
          overflow-y: visible;

        .day-column {

          display: inline-block;
          border-left: 1px solid;
          border-color: #DDD;
          background-color: #FFF;

          &.weekend-day {
            background-color: #F4F4F4;
          }

          &.first-of-month {
            border-color: #888888;
          }

          .day-name {
            text-align: center;
            border-bottom: 1px solid #888;
            padding: 0.4rem 0 0.4rem 0;
          }
        }
      }

      .sprint-bar {
        display: inline-block;
        background: rgba(215, 215, 255, 0.75);

        .inner {
          display: flex;
          justify-content: space-between;
        }
      }

      .project-bar {
        display: inline-block;
        background: rgba(215, 235, 215, 0.75);
        margin-left: 1rem;

        .card-body {
            padding: 0.8rem;
        }
      }

      .task-bar {
        display: inline-block;
        background: rgba(195, 129, 197, 0.75);
        margin-left: 1rem;

        .card-body {
          padding: 0.8rem;
        }
      }
    }
  }
}
