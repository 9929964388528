.splash-page {
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  
    // Large Viewport with sidebar visible
    @media (max-width: $grid-lg-max) {
    .show-sidebar & {
      flex-direction: column;  
    }
  }

  // Medium Viewport
  @media (max-width: $grid-md-max) {
    flex-direction: column;
  }

  .left-column {
    flex: 1;
    position: relative;
  }

  .right-column {
    flex: 0;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1em;
    min-width: 25rem;

    // Large Viewport with sidebar visible
    @media (max-width: $grid-lg-max) {
      .show-sidebar & {
        margin-top: 1em;
        min-width: unset;
        margin-left: 0;
      }
    }

    // Medium Viewport
    @media (max-width: $grid-md-max) {
      margin-top: 1em;
      min-width: unset;
      margin-left: 0;
    }

    >* {
      box-shadow: $shadow;
      width: 100%;
    }

    >*:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .news-section {
    display: flex;
    flex-direction: column;

    >*:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .card {
    margin-bottom: 0;

    &.notifications {
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          margin: 0;
        }
      }

      .notification-scroll-parent {
        height: 300px;
        overflow-x: hidden;
        overflow-y: auto;

        .fixed {
          display: none;
        }

        // Large Viewport with sidebar visible
        @media (max-width: $grid-lg-max) {
          .show-sidebar & {
            height: auto;
            overflow-y: visible;

            .infinite {
              display: none;
            }
            .fixed {
              display: block;
            }
          }
        }

        // Medium Viewport
        @media (max-width: $grid-md-max) {
          height: auto;
          overflow-y: visible;

          .infinite {
            display: none;
          }
          .fixed {
            display: block;
          }
        }
      }
    }
  }
}
