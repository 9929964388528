.news-article-page {
  max-width: 50rem;

  &.container-fluid {
    padding-bottom: 1.25rem;
  }

  .card {
    box-shadow: $shadow;
  }

  .news-article-wrapper {
    padding: 1em;
    position: relative;
    display: flex;
    justify-content: center;

    .error-indicator,
    .loading-indicator {
      width: 100%;
      height: 15rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 0.5em;
      }
    }

    .error-indicator {
      flex-direction: column;
      .btn {
        margin: 0.5em;
      }
    }
  }
}
