.widget.dashboard-widget {
  display: flex;
  padding: 1em 0.5em;

  .chart-wrapper {
    margin: 0 0.5em;
    flex-basis: calc(50% - 1em);
    max-width: calc(50% - 1em);
    box-shadow: $shadow;
    border: 1px solid $gray-400;
    padding: 1em;

    .chart-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
