
$swimlane-border-radius: 0.25rem;
$swimlane-card-border: 1px solid $gray-300;
$compact-padding: 0.4rem;

&.compact {
  &>.card-body {
    padding: $compact-padding;
  }
  .swimlane-scroll-container {
    .swimlane-column-container {
      padding: $compact-padding;
      .swimlane-column.card {
        border-radius: 0px;
      }
      &:first-of-type {
        padding-left: $compact-padding;
      }
      &:last-of-type {
        padding-right: $compact-padding;
      }
      .swimlane-column.card {
        &>.card-body {
          padding: $compact-padding;
          &>.card-title {
            padding: $compact-padding $compact-padding 0 $compact-padding;
          }
        }
        .swimlane-card {
          margin-bottom: 0.8rem;
          .card-body {
            padding: 0.8rem;
          }
        }
      }
    }
  }
}

.swimlane-scroll-container {

  display: flex;
  align-items: stretch;
  overflow-x: auto;
  white-space: normal;

  .hidden-column-placeholder {
    border-right: 1px dashed silver;
    padding-left: 0.2rem;
    margin-right: 0.2rem;
    cursor: help;
  }
  .swimlane-column-container {
    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      padding-right: 1rem;
    }
    border-radius: $swimlane-border-radius;
    min-width: 28rem;
    max-width: 30rem;
    flex-grow: 1;
    padding: 1rem 0.5rem 0 0.5rem;
    display: flex;
    align-items: stretch;
    .swimlane-column.card {
      &.is-dropping-over {
        border-color: rgba(128, 128, 255, 0.5);
        box-shadow: 0 0 0.25rem rgba(128, 128, 255, 0.5);
      }
      width: 100%;
      background-color: $gray-200;
      border-radius: $swimlane-border-radius;
      border: $swimlane-card-border;

      &>.card-body {
        padding-top: 0.8rem;
      }

      .swimlane-card {
        border-radius: $swimlane-border-radius;
        border: $swimlane-card-border;
        // .fa-save {
        //   animation: blink 2s ease-in-out infinite;
        // }
      }
    }
  }
}
