.collection-widget {
  .collection-widget-header {
    display: flex;
    padding: 7px 12px;
    background: $white;
    border-bottom: 1px solid #e9e9e9;
    .table-widget-heading {
      font-size: 14px;
      font-weight: 500;
    }
    .add-btn {
      margin-left: 10px;
    }
    .btn-default.pull-right {
      margin-right: 0;
    }

    .heading {
      flex: 1;
      font-weight: 500;

      i+span,
      .btn {
        margin-left: 0.5em;
      }

      .heading-row {
        font-weight: 400;

        &:first-of-type {
          display: flex;
          min-height: 2em;
          align-items: center;
          font-weight: 500;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .table-widget-footer {
    position: absolute;
    bottom: 0;
    padding: 10px;
    background: white;
    border-top: 1px solid #48b6d7;
    border-bottom: 2px solid #48b6d7;
    border-right: 1px solid #48b6d7;
    width: 100%;
    border-left: 6px solid #37a7c8;
    .ci-footer-label {
      margin: 5px 0;
      width: 260px;
      margin-right: 20px;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
  }
}
