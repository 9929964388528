.tabs-with-more {
  overflow-x: hidden;

  .customtab {
    flex-wrap: nowrap;
  }

  .nav-tabs .nav-link {
    margin-bottom: 0;

    .badge-count-wrapper {
      display: inline-block;

      .badge {
        margin-left: 0.5em;
        vertical-align: text-bottom;
      }
    }
  }

  .offscreen-tab-container {
    position: fixed;
    flex-wrap: nowrap;
    top: 0;
    left: 0;
    visibility: hidden;
  }

  .more {
    position: absolute;
    right: 0.5em;
    span {
      margin-right: 0.5em;

      +.badge-count-wrapper {
        margin-left: -0.5em;
        margin-right: 0.5em;
      }
    }
  }
}
