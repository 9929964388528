ul.customtab{
  background-color: $gray-100;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 400;

  li {
    &.nav-item {
      margin-bottom: 0;

      &.active {
        margin-bottom: -1px;
      }
    }

    a.nav-link {
      margin-top: 0.5em;
      margin-right: 2px;
      border: 1px solid transparent;
      padding: 1em 1.5em 1em;
      color: $gray-700;
      user-select: none;
      position: relative;
      background-color: $gray-100;
      white-space: nowrap;

      &.active {
        border: 1px solid $gray-400;
        border-top: 2px solid $themecolor;
        color: $themecolor;

        &:before {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          border: 0;
          border-bottom: 1px solid white;
          z-index: 1;
        }
      }
      &:hover {
        color: $themecolor;
      }
    }
  }
}
