.portal-data-table {
  .portal-datatable-row {
    &:hover td {
      background-color: mix(white, $ci-blue, 90%);
      border: 1px solid #d4dde7;
    }

    &.active td {
      color: black;
      background-color: mix(white, $ci-blue, 80%);
      border: 1px solid #bbcde1;
      
      // .project-number a, a {
      //   color: black;
      // }
    }
    
    &.active:hover td {
      background-color: mix(white, $ci-blue, 80%);
      border: 1px solid #a9bcd2;
    }
  }
}
