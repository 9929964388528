.widget.collection-widget.notes-widget {
  .collection-widget-items-container {
    max-height: 55vh;
    overflow-x: auto;
    .collection-widget-items-inner {
      flex-direction: column;
    }

    .row-wrapper {
      padding: 8px 5px;

      .user-avatar-container {
        @include user-avatar-container;
      }

      .note-details {
        display: inline-block;
        white-space: normal;

        #tiny.body, .mce-content-body {
          max-width: 600px;
          padding: 4px;
        }

        p {
          margin: 0;
        }
      }

      .by-line {
        font-style: italic;
        color: $gray-500;

        .form-buttons {
          margin-left: 0.5em;
        }
      }
    }

    .portal-form {
      @include striped-children;
    }
  }
}
