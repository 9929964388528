$light-border: rgba(255, 255, 255, 0.5);
$dark-border: rgba(0, 0, 0, 0.25);
// $dark-border: #ffd690;

.date-select {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 10px 0 0;
  width: 100%;
  max-width: 100px;
}

.active-projects-table {
  overflow-x: scroll;
  tr {
    transition: background-color 0.33s;
    background-color: rgba(0, 0, 0, 0.025);
    &:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
  .month-header th {
    font-size: 18px;
    font-weight: 800;
    padding: 10px;
    text-align: center;
    background-color: #99bbee;
    border-color: $light-border;
    color: #fff;
    border-bottom: none;
    &.bordered {
      border-right: 1px solid $light-border;
      &:last-of-type {
        border-right: none;
      }
    }
  }
  .financial-column-headers th {
    background-color: #688dc5;
    border-color: $light-border;
    color: #fff;
    border-bottom: none;
    &.bordered {
      border-right: 1px solid $light-border;
      &:last-of-type {
        border-right: none;
      }
    }
  }
  .state-rows {
    border-bottom: 1px solid $dark-border;
    td {
      // font-weight: 800;
      padding-top: 16px;
      padding-bottom: 16px;
      background-color: #efefef;
      // border-color: $dark-border;
      color: #669;
      vertical-align: middle;
      cursor: pointer;
      &.bordered {
        border-right: 1px solid $dark-border;
        &:last-of-type {
          border-right: none;
        }
      }
      &.name {
        min-width: 100px;
        white-space: nowrap;
      }
      &.count {
        // max-width: 40px;
        white-space: nowrap;
      }
    }
  }
  .user-rows td {
    padding-top: 8px;
    padding-bottom: 8px;
    // font-weight: 600;
    vertical-align: middle;
    cursor: pointer;
    min-width: 120px;
    &.bordered {
      border-right: 1px solid $dark-border;
      &:last-of-type {
        border-right: none;
      }
    }
    &.name {
      min-width: 100px;
      white-space: nowrap;
    }
    &.count {
      max-width: 40px;
    }
  }
  .project-rows td {
    &.name {
      min-width: 100px;
      white-space: nowrap;
    }
    vertical-align: middle;
    font-weight: 400;
    &.bordered {
      border-right: 1px solid $dark-border;
      &:last-of-type {
        border-right: none;
      }
    }
  }
  &-exit-active {
    opacity: 1;
  }
  &-exit-done {
    opacity: 0;
  }
  &-appear {
    opacity: 1;
  }
  &-appear-active {
    opacity: 0;
  }
}

td.currency-range {
  // font-family: 'Monaco Regular', Courier, monospace;
  // font-weight: 600;
  &.neutral {
    color: #323539;
  }
  &.positive {
    // color: #157e00;
    color: #333;
  }
  &.negative {
    // background-color: #e9c3bf;
    // color: #7d0c00;
    color: $green;
  }
}

.animated-background-loading {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}
