
.dtoolslink-info-panel-container {
  background: #edf1f5;
  border: 1px solid #DDD;
  .dtoolslink-info-panel {
    table {
      td, th {
        border-right: 0;
        border-left: 0;
        white-space: normal;
      }
    }
  }
}
