
.myob-transaction-report {
  .report-title {
    display: flex;
    justify-content: space-between;
  }
  .category-sort-selector {
    display: inline-block;
    .selector-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 15rem;
      padding: 0.3rem 0.5rem 0.3rem 1rem;
      border-bottom: 1px solid $border-color;

      .buttonbar {
        display: flex;
        align-items: center;
      }
    }
  }
}
