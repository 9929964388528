.portal-modal.paste-data-from-clipboard {
  .import-options {
    .form-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0;

      >* {
        white-space: nowrap;
        margin-left: 1em;

        &:first-child {
          margin-left: 0;
        }
      }

      input {
        max-width: 3em;
      }
    }
  }
}
