$news-column-count-xl: 4;
$news-column-count-lg: 3;
$news-column-count-md: 3;
$news-column-margin: 1em;

$fakeArticleForegroundColour: $gray-400;
$fakeArticleBackgroundColour: $gray-200;

@keyframes background-pulse
{
  0% {
    background-color: rgba($fakeArticleForegroundColour, 0.5);
  }
  100% {
    background-color: $fakeArticleForegroundColour;
  }
}


.article {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 1em;
  line-height: 1.25em;
  background-color: $white;
  box-shadow: $shadow;
  color: $body-color;

  // When the article is under a teaser list that is in column layout
  // column layouts only apply after grid size md
  @media (min-width: $grid-md-min) {
    .column-layout & {
      margin-left: 0.5em;
      margin-right: 0.5em;
      flex-basis: calc(#{calc(100% / $news-column-count-xl)} - 1em);

      @media (max-width: $grid-lg-max) {
        flex-basis: calc(#{calc(100% / $news-column-count-lg)} - 1em);
      }

      @media (max-width: $grid-md-max) {
        flex-basis: calc(#{calc(100% / $news-column-count-md)} - 1em);
      }
    }
  }

  p {
    margin: 0;
  }


  .inner-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;

    // column layouts only apply after grid size md
    @media (min-width: $grid-md-min) {
      .column-layout & {
        flex-direction: column;
      }
    }

    // in grid size xs, the image and text are layed out vertically
    @media (max-width: $grid-xs-max) {
      flex-direction: column;
    }

    .image-container {
      position: relative;
      flex: 1;
      overflow: hidden;
      background-color: $gray-200;
      min-height: 15em;

      @media (min-width: $grid-lg-min) and (max-width: $grid-lg-max) {
        min-height: 12em;
      }

      @media (max-width: $grid-xs-max) {
        min-height: 50vmin;
      }

      .image {
        position: absolute;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .text-container {
      flex: 2;
      display: flex;
      align-items: flex-end;
      position: relative;
      height: 100%;

      .text-wrapper {
        position: relative;
        font-size: 1rem;
        padding: 1em;
        width: 100%;
        height: 100%;

        @media (max-width: $grid-sm-max) {
          font-size: 1.25rem;
        }

        @media (max-width: $grid-xs-max) {
          font-size: 4vmin;
        }

        .text-background {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .header {
          position: relative;
          display: inline-block;
          justify-content: space-between;
          align-items: flex-start;
          font-size: 1.25em;
          line-height: 1.15em;
          margin-bottom: 0.25em;
          font-weight: 500;
        }

        .article-info {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 0.5em;

          .avatar-wrapper {
            border-radius: 100%;
            width: 2em;
            height: 2em;
            overflow: hidden;
            display: inline-flex;
            justify-content: space-around;
            align-items: flex-start;
            margin-right: 0.5em;

            .user-avatar {
              background: $white;
              object-fit: cover;
              width: 100%;
            }
          }

          .author-and-date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 0.75em;
            line-height: 1.25em;

            .author {
              font-weight: 500;
              margin-right: 0.5em;
            }

            .publish-date {
              color: $gray-500;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              line-height: 1em;

              .badge {
                text-shadow: none;
              }

              span+.badge {
                margin-left: 0.5em;
              }
            }
          }
        }

        .teaser-text {
          position: relative;
          font-size: 1em;
          line-height: 1.15em;
          font-weight: 300;
        }
      }
    }
  }

  &.primary {
    // Primary article styling only applies for grid size sm and above
    @media (min-width: $grid-sm-max) {

      color: $white;
      text-shadow: 1px 1px 3px rgba(63, 63, 63, 1);

      .inner-wrapper {
        padding-top: 50%; // make it 2:1
        align-items: flex-end;

        @media (max-width: $grid-sm-max) {
          min-height: 20em;
        }
        @media (max-width: $grid-xs-max) {
          min-height: 15em;
        }

        .image-container {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        .text-container {
          flex: 1;
          border: none;

          .text-background {
            background-color: rgba(0, 0, 0, 0.5);
          }

          .text-wrapper {
            height: unset;
            .article-info {
              .author-and-date {
                .publish-date {
                  color: $gray-100;
                }
              }
            }
          }
        }
      }
    }
  }

  &.fake {
    .text-container .text-wrapper .text-background {
      background-color: unset;
    }

    .placeholder {
      background-color: $fakeArticleForegroundColour;
      animation: background-pulse 0.75s infinite alternate ease;
    }

    .header,
    .author-and-date {
      width: 100%;
      flex: 1;
      .placeholder {
        width: 70%;
        height: 1.25em;
      }
    }
    .avatar-wrapper .placeholder {
      width: 100%;
      height: 100%;
    }
    .author, .publish-date {
      width: 100%;
    }
    .author .placeholder {
      width: 60%;
      height: 1em;
      margin-bottom: 0.5em;
    }
    .publish-date .placeholder {
      width: 30%;
      height: 1em;
    }
    .teaser-text {
      .placeholder {
        height: 0.75em;
        width: 100%;
        margin-bottom: 0.25em;

        &:last-of-type {
          width: 75%;
          margin-bottom: 0;
        }
      }
    }
  }
}

.read-more-link {
  display: flex;
  justify-content: flex-start;
  >* {
    color: $gray-500;
  }
}
