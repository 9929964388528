/**
 *  Transitions
 */
.portal-form,
.portal-login-form {
  // Fade out deleted inline form row
  &.fade-out {
    animation: deleteFade 1s both;
  }

  /**
   * Basic Styling
   */
  .portal-data-field {
    padding: 0.375rem 0.75rem;
    padding-left: 0;
    &.not-set {
      color: $gray-400;
    }
  }

  &.vertical-layout {
    width: 100%;

    @media (min-width: $grid-lg-min) {
      max-width: 800px !important;
    }

    // @todo was there a better way to apply this?
    .progress-percent-editor {
      line-height: 25px;
    }
  }

  .form-horizontal {
    .portal-data-field {
      line-height: 25px;
      min-height: 38px;
      margin: 0 calc(0.75rem + 1px);
      padding-top: calc(0.375rem + 1px);
    }

    .nav-link{
      display: inline-block;
      padding: 0.5rem 0;
    }

    .no-button-group {
      margin-top: 1rem;
    }

    .alert {
      margin-top: 1rem;
      white-space: normal;
    }

    .portal-data-field,
    .form-control {
      &+.form-text.text-muted {
        margin: 0.25rem calc(0.75rem + 1px);
      }
    }

  }

  /**
   *  Error States
   */
  .inline-error {
    border-radius: 0;
    margin: 0;
    font-size: 90%;
    animation: borderedJaw 0.2s ease 1 both;
  }

  .alert.inline-error,
  .form-control-feedback {
    & > ul {
      list-style: none;
      padding-left: 0;
      & > li {
        padding: 6px 0px;
      }
      margin: 0;
    }
  }

  /**
  * Theme Overrides
  */
  .form-group {
    .control-label {
      font-weight: 500;

      // If a form field has a formFieldToolTip then pad it on the left
      .info-tooltip-wrapper {
        margin-left: 0.5em;

      }
    }
    margin-bottom: 0.5em;
  }

  .form-control:focus {
    outline: 0px !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  /**
   * Material form theme extension
   *
   * Begining of an error state fix for form-material if we wish to use it.
   *
   * @todo potentially implement this
   */
  &.form-material {
    .form-control-danger,
    .form-control.form-control-danger.focus,
    .form-control.form-control-danger:focus {
      background-image: linear-gradient($danger, $danger), linear-gradient($gray-200, $gray-200);
      border: 0 none;
      border-radius: 0;
      box-shadow: none;
      float: none;
    }
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.portal-data-field {
  i+span, span+i {
    margin-left: 0.5em;
  }
}

