.split-handle {
  position: absolute;
  height: 100%;
  margin-left: -4px;
  width: 8px;
  top: 0;
  left: 0;
  cursor: ew-resize;

  &:hover,
  &.dragging {
    background-color: rgba($gray-100, 0.5);
  }

  // Touch only devices
  @media (any-hover: none) {
    width: 2em;
    margin-left: -1em;
  }
}
