.alert {
  animation: coolToast 1s ease;
}

.inline-error.alert-dismissible {
  .close {
    padding: 0.1rem;
  }
}

.alert {
  border: none;
  &.alert-danger {
    border-left: 4px solid $red;
  }
  &.alert-success {
    border-left: 4px solid $green;
  }
  &.alert-info {
    border-left: 4px solid $info;
  }
  &.alert-warning {
    border-left: 4px solid $warning;
  }
  & > ul {
    list-style: none;
    padding-left: 0;
    & > li {
      padding: 3px 0px 0px;
    }
    margin: 0;
    // padding-left: 24px;
  }
}
