.file-select-input {
  position: relative;

  &:not(.disabled) {
    >input {
      // The input is ALWAYS disabled - so only make it look disabled when the control is disabled... if that makes sense
      &:disabled {
        opacity: 1;
      }
    }
  }

  >input[type="file"] {
    display:none;
  }

  >input[type="text"] {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 3em; // Accommodate the button
  }

  &.has-selection {
    >input[type="text"] {
      padding-right: 5em; // Accommodate the button and the clear button
    }
  }

  >.upload-progress{
    background-color: $primary;
    opacity: 0.25;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    transition: width 0.1s ease;
  }

  >.btn.clear {
    position: absolute;
    top: 1px;
    right: 3em;
    bottom: 1px;
    background-color: transparent;
    border: none;
  }

  >.btn.browse {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
  }

  .file-drop-target {
    .drop-zone-indicator {
      right: 4em;
    }
  }

  // When displayed in a portal inline form
  .file-input & {
    width: 100%;
    max-width: 100%;

    >input[type="text"] {
      outline: none;
      min-height: 28px;
      padding: 3px 3em 3px 8px;
      background: transparent;
      text-align: left;
      border: none;
    }

    >.btn.browse {
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 0.5em;
    }

    >.btn.clear {
      top: 0;
      right: 2.25em;
      bottom: 0;
      padding: 0 0.5em;
    }

    &.has-selection {
      >input[type="text"] {
        padding-right: 3.5em; // Accommodate the button and the clear button
      }
    }
  }

}
