.modal-wizard {
  margin: -1rem;

  .modal-wizard-page-wrapper {
    min-height: calc(50 * var(--vh));
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    --content-height: 0; // This content height is overridden in the javascript based on the dynamic content
    --content-width: 0; // This content width is overridden in the javascript based on the modal width and is used to accommodate the dynamically visible scrollbar
    --scroll-bar-width: 0; // This width is overridden in the javascript based on the device's detected scrollbar width
    height: calc(var(--content-height) + 2.1rem);
    transition: height 250ms ease;
    transition-delay: 50ms;
    // 1.75rem = modal margin top * bottom
    // 3rem = modal header height
    // 7rem = modal wizard button row
    max-height: calc(100 * var(--vh) - (1.75rem * 2) - 3rem - 7rem);
    @media (max-width: $grid-xs-max) {
      overflow-y: visible;
      max-height: unset;
    }

    &.transitioning {
      overflow-y: hidden;
    }
  }

  .modal-wizard-button-row {
    padding: 2rem 1rem 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid $dropdown-border-color;

    @media (max-width: $grid-xs-max) {
      // Don't accommodate the page indicator in mobile mode
      padding: 0 1rem 1rem;
    }

    .row-buttons {
      position: relative;
      display: flex;
      flex-direction: row;
      @media (max-width: $grid-xs-max) {
        flex-direction: column;
      }

      &.left {

        >* {
          margin-right: 1rem;
        }
      }

      &.right {
        >* {
          margin-left: 1rem;
        }
      }

      button {
        margin-top: 1rem;

        span+i, i+span{
          margin-left: 0.5em;
        }
      }
    }
  }
}
