.custom-select-container {

  .input-wrapper {
    // FireFox
    input[type='number'] {
      -moz-appearance: textfield;
    }

    // Webkit
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

}

