.file-input {
  position: relative;

  &.not-set {
    color: $gray-400;
  }

  .filename-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .text-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 1em;
      overflow: hidden;

      >i {
        margin-right: 0.5em;
      }

      >span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      >button {
        margin-left: 0.5em;
        font-size: 11px;
        padding: 0.25em 0.5em;
        border-radius: 0.2em;
      }
    }
  }

  // When displayed in a portal inline form
  .portal-data-field.inline & {
    width: 100%;
    max-width: 100%;

    .filename-wrapper {
      justify-content: space-between;
    }
  }
}
