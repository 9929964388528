.news-article-teaser-list {

  // Placing the .column-layout class on the teaser list will force it into column mode
  // column layouts only apply after grid size md
  @media (min-width: $grid-md-min) {
    &.column-layout {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      justify-content: flex-start;

      margin-left: -0.5em;
      margin-right: -0.5em;
    }
  }

  .more-news-link-wrapper {
    margin-top: 1rem;

    @media (max-width: $grid-sm-max) {
      padding: 0 1rem;
    }

    .more-news-link {
      >* {
        margin: 0;
      }
    }
  }
}
