// @note this value must match the javascript WIZARD_PAGE_TRANSITION_DURATION constant in modal-wizard-page.tsx
$wizardPageTransitionDuration: 250ms;

@keyframes modalWizardPageInLeft {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes modalWizardPageOutLeft {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: -100%;
  }
}

@keyframes modalWizardPageInRight {
  0% {
    opacity: 0;
    left: -100%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes modalWizardPageOutRight {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
}


.modal-wizard-page {
  opacity: 0;
  visibility: hidden;
  padding: 1rem;
  position: absolute;
  top: 0;
  // It can be annoying that the width is always offset by a potential scrollbar, but
  // the alternative is that the content can jump around while the wizard is resizing
  // during page transition. This way the content is consistently the same width.
  width: calc(var(--content-width) - var(--scroll-bar-width));

  &.in {
    visibility: visible;

    &.left {
      animation: modalWizardPageInLeft $wizardPageTransitionDuration ease forwards;
    }
    &.right {
      animation: modalWizardPageInRight $wizardPageTransitionDuration ease forwards;
    }
  }

  &.out {
    visibility: visible;
    animation: modalWizardPageOut $wizardPageTransitionDuration ease forwards;

    &.left {
      animation: modalWizardPageOutLeft $wizardPageTransitionDuration ease forwards;
    }
    &.right {
      animation: modalWizardPageOutRight $wizardPageTransitionDuration ease forwards;
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .error-row-spacer {
    margin-top: 0;
  }

  .error-row {
    margin-top: 0.5em;
    font-size: 0.8em;
    color: $danger;
  }

  .wizard-page-section {
    .wizard-page-section-heading {
      font-weight: 500;
      margin-bottom: 1em;
    }

    .wizard-page-section-content {
      padding-left: 1em;

      @media(max-width: $grid-xs-max) {
        padding-left: 0;
      }

      >* {
        margin-bottom: 1em;
      }

      .wizard-form {
        display: grid;
        column-gap: 1em;
        grid-template-columns: max-content auto;
        padding-left: 1em;

        >* {
          margin-top: 1em;

          &:nth-child(1), &:nth-child(2) {
            margin-top: 0;
          }
        }

        @media (max-width: $grid-xs-max) {
          >* {
            margin-top: 0;
            &:nth-child(2n+1) {
              margin-top: 1em;
            }
          }

          grid-template-columns: auto;
        }

        >label {
          display: flex;
          align-items: center;
          font-weight: 500;
          margin-bottom: 0;

          @media (max-width: $grid-xs-max) {
            margin-bottom: 0.5em;
          }

          &.danger {
            color: $danger;
          }
        }

        >span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 1em;
          min-height: 38px;
        }

        .form-control {
          &.form-control-danger {
            border-color: $danger;
          }
        }
      }

      .wizard-item-progress-wrapper {
        .wizard-item-progress {
          margin-bottom: 0.5em;

          .item-label-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 1.5em;

            .item-icon {
              font-size: 1.5em;
              vertical-align: middle;

              &img {
                width: 1.5em;
                height: 1.5em;
              }

              &i {
                width: 1em;
                height: 1em;
              }

              margin-right: 0.25em;

              &.danger {
                color: $danger;
              }

              &.success {
                color: $success;
              }
            }

          }

          .item-progress-wrapper {
            height: 0.5em;
            width: 20em;
            position: relative;

            .progress {
              height: 100%;
              margin: 0.25em 0 0 2.5em;
            }
          }

          .item-error-wrapper {
            color: $danger;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
