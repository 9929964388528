.year-month-picker {
  .form-group {
    >* {
      margin-right: 0.5em;
    }
  }
  .month-select {
    min-width: 5.5em;
  }
  .year-select {
    min-width: 6.5em;
  }
}
