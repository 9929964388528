.birthday-select {
  display: flex;

  >.Select {
    flex: 1;
    margin-right: 0.5em;

    &.year {
      flex: 6;
    }

    &.month,
    &.day {
      flex: 3;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
