.progress {
  background: $gray-200;
  margin: 10px;
  border-radius: 2px;

  .progress-bar {
    transition: width 0.25s ease;

    &.progress-bar-striped {
      $stripe: rgba(255, 255, 255, 0.2);
      background-image: linear-gradient(
        143deg,
        transparent 0px,
        transparent 100px,
        $stripe 100px,
        $stripe 200px,
        transparent 200px,
        transparent 300px
      );
      background-size: 350px 300px;
      background-position: 0 0;
      animation: backgroundScroll 0.66s linear infinite normal;
    }
  }

  &.complete {
    animation: swimAway 0.5s ease-out 0.2s 1 forwards;

    .progress-bar {
      width: 100%;

      &.progress-bar-striped {
        width: 100%;
        background-position-x: 350px;
        animation: none;
      }
    }
  }
}
