.sort-by-dropdown {
}

.sort-by-dropdown-item {
  // This ensures the click event is passed to the dropdown item, not the span or the icon
  >*{
    pointer-events: none;
  }
  span {
    padding-left: 0.5em;
  }
}
