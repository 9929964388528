.loading-spinner {
  width: calc(100% - 1em);
  padding: 0 0.5em;
  min-height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  img.spinner {
    margin-right: 0.5em;
  }
}
