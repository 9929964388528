@font-face {
	font-family: 'Roboto';
	src: url('./Roboto/Roboto-Light-webfont.eot');
	src: url('./Roboto/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
			 url('./Roboto/Roboto-Light-webfont.woff') format('woff'),
			 url('./Roboto/Roboto-Light-webfont.ttf') format('truetype'),
			 url('./Roboto/Roboto-Light-webfont.svg#robotolight') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./Roboto/Roboto-Regular-webfont.eot');
	src: url('./Roboto/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
			 url('./Roboto/Roboto-Regular-webfont.woff') format('woff'),
			 url('./Roboto/Roboto-Regular-webfont.ttf') format('truetype'),
			 url('./Roboto/Roboto-Regular-webfont.svg#robotoregular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./Roboto/Roboto-Medium-webfont.eot');
	src: url('./Roboto/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
			 url('./Roboto/Roboto-Medium-webfont.woff') format('woff'),
			 url('./Roboto/Roboto-Medium-webfont.ttf') format('truetype'),
			 url('./Roboto/Roboto-Medium-webfont.svg#robotomedium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./Roboto/Roboto-Bold-webfont.eot');
	src: url('./Roboto/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
			 url('./Roboto/Roboto-Bold-webfont.woff') format('woff'),
			 url('./Roboto/Roboto-Bold-webfont.ttf') format('truetype'),
			 url('./Roboto/Roboto-Bold-webfont.svg#robotobold') format('svg');
	font-weight: 700;
	font-style: normal;
}
