.portal-form.company-location {
  .location-form-headers {
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid $gray-300;

    .google-map-wrapper {
      background-color: $body-bg;
      margin: 0 0 10px;
      min-height: 15rem;
      border-bottom: 1px solid $border-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $text-muted;
      position: relative;
    }
  }
}
