.notification-container {
  width: 100vw;

  @media (min-width: $grid-sm-min) {
    width: 75vw;
  }

  @media (min-width: $grid-md-min) {
    width: 60vw;
  }

  @media (min-width: $grid-lg-min) {
    width: 40vw;
  }

  @media (min-width: $grid-xl-min) {
    width: 30vw;
  }

  max-width: 500px;

  // Hide the scrollbar in the notification container (only works in webkit browsers)
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.notification-container.notification,
.notification {
  opacity: 1;

  background-color: $notification-bg;
  color: $notification-color;
  padding: 15px 15px 15px calc(4rem + 15px);
  border: 0.1em solid $notification-bg;

  &::before {
    font-size: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    width: 4rem;
    height: unset;
    background-color: $notification-bg;
    color: $notification-color;
  }

  &.notification-info {
    border-color: $info;
    &::before {
      background-color: $info;
      color: $white;
    }
  }
  &.notification-success {
    border-color: $success;
    &::before {
      background-color: $success;
      color: $white;
    }
  }
  &.notification-warning {
    border-color: $warning;
    &::before {
      background-color: $warning;
      color: $white;
    }
  }
  &.notification-error,
  &.notification-danger {
    border-color: $danger;
    &::before {
      content: "\f057";
      background-color: $danger;
      color: $white;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}
