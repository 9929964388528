/**
 * Override styles for react-select contextually
 **/

.Select {
  border-radius: 0.25rem;

  &.form-control {
    padding: 0;
    border: 0;

    &.form-control-danger {
      .Select__control {
        border-color: $danger;
      }
    }
  }

  .Select__control {
    border-color: $input-border-color;
    box-shadow: 0 0 0 0;

    &:hover {
      border-color: $input-border-color;
      background-color: $input-bg;
    }

    &.Select__control--is-focused {
      border-color: $input-focus-border-color;
    }

    &.Select__control--is-disabled {
      opacity: 0.7;
      background-color: $input-bg;
      border-color: $input-border-color;

      .Select__indicators {
        display: none;
      }
    }

    .Select__dropdown-indicator {
      padding: 0.5em;
    }

    .Select__value-container {
      padding: 0.375rem 0.75rem;

      [class*="-Input"] {
        margin: 0;
        padding: 0;
      }

      .Select__placeholder {
        color: $input-placeholder-color;
      }

      .Select__single-value {
        margin-left: 0;
      }

      .Select__input {
        input {
          opacity: 1 !important;
        }
      }
    }
  }

  &.no-border {
    border-radius: 0;

    .Select__control {
      border: 0;

      &.Select__control--is-focused {
        border: 0;
      }
    }
  }

  &.async-select.focused {
    .Select__control {
      .Select__value-container {
        .Select__single-value {
          color: $text-muted;
        }
      }
    }
  }
}

// Styles of the dropdown need to be defined outside of the .Select class
// as the menu is rendered in a portal elsewhere in the DOM
.Select__menu {
  &[class*="-menu"] {
    margin-top: 0.175rem;
  }

  .Select__option {

    i+span {
      margin-left: 0.5em;
    }

    &:hover {
      background-color: $gray-200;
    }
  }

  .Select__option--is-focused {
    background-color: $gray-200;
  }
  .Select__option--is-selected {
    background-color: var(--info);
    &:hover {
      background-color: var(--info);
    }
  }
}
